import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTextEnterSwoosh } from '../../hooks/textEnterSwoosh.transition.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import PageSection from '../PageSection/PageSection';
import Widowless from '../Widowless/Widowless';
import './DefaultPageHeader.scss';

type DefaultPageHeaderProps = {
  id: string,
  title: string,
  titleFontSize?: 'md' | 'lg',
  className?: string,
  subtitle?: string,
  hideTitle?: boolean,
  animateTitle?: boolean,
}

const DefaultPageHeader: React.FC<DefaultPageHeaderProps> = props => {
  const headingOneRef = useObservableRef<HTMLHeadingElement>();
  {props.animateTitle == false ? 
    null
    : 
    useTextEnterSwoosh(props.hideTitle ? undefined : headingOneRef, { delay: 100 })
  }
  return <Observer children={() => (
    <PageSection
      id={props.id}
      as="header"
      className={joinClassName('DefaultPageHeader', props.className, props.hideTitle && 'hideTitle')}
      observeVisibility
    >
      <h1 className="DefaultPageHeaderTitle" data-size={props.titleFontSize ?? 'lg'} ref={headingOneRef}>{props.title}</h1>
      {props.subtitle && <Widowless className="DefaultPageHeaderSubtitle">{props.subtitle}</Widowless>}
      {props.children && <div className="DefaultPageHeaderContent">
        {props.children}
      </div>}
    </PageSection>
  )} />
}

export default DefaultPageHeader;