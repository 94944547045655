import { graphql, useStaticQuery } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Hashtag } from '../../constants/hashtags.constants';
import { useFIARallyMarshalsTrainingInfo } from '../../content/projects/fia-rally-marshals-training.project';
import { useHopOnHopOffInfo } from '../../content/projects/hoponhopoff.project';
import { useSoluColInfo } from '../../content/projects/solucol.project';
import { useAppContext } from '../../controllers/app.controller';
import { isBrowser, isBuildTime } from '../../env';
import { DesignGalleryPrintImagesQuery } from '../../types/generated';
import { ColorPalette } from '../../utils/colors.utils';
import GatsbyImageAdaptor from '../GatsbyImageAdaptor/GatsbyImageAdaptor';
import GridRow from '../GridRow/GridRow';
import './DesignGalleryPrint.scss';

type DesignGalleryPrintProps = {}

const DesignGalleryPrint: React.FC<DesignGalleryPrintProps> = props => {
  const { UI } = useAppContext();
  const DesignGalleryPrintImages: DesignGalleryPrintImagesQuery = useStaticQuery(graphql`
    query DesignGalleryPrintImages {
      yesMilk: file(relativePath: {eq: "images/yes-milk/yes-milk-branding-packaging.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      },
      keithBarryPoster: file(relativePath: {eq: "images/keith-barry/keith-barry-magic-madhouse-poster-design.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      },
      keithBarryPosterWithInformation: file(relativePath: {eq: "images/keith-barry/keith-barry-magic-madhouse-poster-design-with-information.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      },
    }
  `)
  const imageMetaArray = [
    useFIARallyMarshalsTrainingInfo().gallery?.[0],
    useSoluColInfo().gallery?.[0],
    {
      image: DesignGalleryPrintImages.yesMilk!.childImageSharp!,
      title: 'YES-milk branding and packaging design concept',
      alt: 'YES-milk branding and packaging design concept',
    },
    {
      image: DesignGalleryPrintImages.keithBarryPoster!.childImageSharp!,
      title: 'Keith Barry Poster Design: Magic Madhouse',
      alt: 'Keith Barry Poster Design: Magic Madhouse',
    },
    {
      image: DesignGalleryPrintImages.keithBarryPosterWithInformation!.childImageSharp!,
      title: 'Keith Barry Poster Design: Magic Madhouse (with information)',
      alt: 'Keith Barry Poster Design: Magic Madhouse (with information)',
    },
    useHopOnHopOffInfo().gallery?.[1],
  ]
  return <Observer children={() => (
    <div className="DesignGallery DesignGalleryPrint" id={Hashtag.printDesign}>
      <header className="DesignGalleryHeader">
        <h2>#Print</h2>
      </header>
      <GridRow>
        <GatsbyImageAdaptor className="DesignGalleryPrintFIAImage" image={imageMetaArray[0]} />
        <GatsbyImageAdaptor image={imageMetaArray[1]} />
        <GatsbyImageAdaptor image={imageMetaArray[2]} />
        {isBrowser && UI.viewport.width >= 1440 && UI.cssFeatures.grid && <GatsbyImageAdaptor className="DesignGalleryPrintKeithImageTall" image={imageMetaArray[3]} objectFit="contain" objectPosition="left"/> }
        {(isBuildTime || UI.viewport.width < 1440 || !UI.cssFeatures.grid) && <GatsbyImageAdaptor className="DesignGalleryPrintKeithImageWide" image={imageMetaArray[4]} />}
        <GatsbyImageAdaptor
          className="DesignGalleryPrintHopOnHopOffImage"
          backgroundColor={ColorPalette.ivory}
          image={imageMetaArray[5]} objectFit="cover" objectPosition="left"
        />
      </GridRow>
    </div>
  )} />
}

export default DesignGalleryPrint;