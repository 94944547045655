import { Link } from "gatsby"
import { Observer } from "mobx-react-lite"
import * as React from "react"
import CTAButton from "../components/CTAButton/CTAButton"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import DesignGalleryAnimations from "../components/DesignGalleryAnimations/DesignGalleryAnimations"
import DesignGalleryBranding from "../components/DesignGalleryBranding/DesignGalleryBranding"
import DesignGalleryDigital from "../components/DesignGalleryDigital/DesignGalleryDigital"
import DesignGalleryPrint from "../components/DesignGalleryPrint/DesignGalleryPrint"
import GridRow from "../components/GridRow/GridRow"
import GridRowEndColumn from "../components/GridRow/GridRowEndColumn"
import Line from "../components/Line/Line"
import PageEndCTASection from "../components/PageEndCTASection/PageEndCTASection"
import PageSection from "../components/PageSection/PageSection"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import QuoteSection from "../components/QuoteSection/QuoteSection"
import StandardTextBlock from "../components/StandardTextBlock/StandardTextBlock"
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef"
import Widowless from "../components/Widowless/Widowless"
import { DesignHashtags } from "../constants/hashtags.constants"
import { useAppContext } from "../controllers/app.controller"
import { PageComponent } from "../types/gatsby.types"
import { isIE } from "../utils/browsers.utils"
import { ColorPalette } from "../utils/colors.utils"
import './design.scss'

const PageDesign: PageComponent = props => {

  const { UI } = useAppContext();

  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className="PageDesign"
      title="Design"
      description="Learn about the design services provided by AxonDivision."
      backgroundColor={ColorPalette.ivory}
      foregroundColor={ColorPalette.black}
    >

      {/* <DecoAxonLines
        id="DesignPageDecoAxonLines"
        enableParallaxEffect
        paths={{
          FG: 'M-66 754L133.42 699.703C135.132 699.236 136.899 699 138.674 699H219.141C222.334 699 225.48 698.236 228.317 696.77L340.462 638.86C344.069 636.997 347.023 634.082 348.933 630.501L351.393 625.888C353.099 622.69 355.642 620.015 358.75 618.15L392.74 597.756C393.579 597.253 394.38 596.688 395.136 596.068L431.814 565.973C432.604 565.325 433.342 564.618 434.023 563.858L648.854 324.092C653.856 318.509 661.559 316.192 668.81 318.089L775.549 346.005C782.611 347.852 790.118 345.705 795.136 340.404L834.083 299.252C837.86 295.261 843.113 293 848.609 293H871.963C876.517 293 880.936 291.445 884.487 288.593L1064.43 144.063C1066.13 142.697 1068.05 141.618 1070.09 140.87L1234 81',
          R: 'M-63 799L136.324 659.571C138.745 657.878 141.51 656.742 144.422 656.245L249.558 638.295C257.667 636.911 264.101 630.703 265.774 622.649L291.433 499.164C293.475 489.341 302.478 482.551 312.484 483.287L608.139 505.053C615.97 505.63 623.413 501.571 627.17 494.676L767.143 237.764C773.241 226.57 788.115 223.862 797.768 232.187L1004.48 410.48C1012.6 417.479 1024.79 416.838 1032.12 409.027L1251 176',
          G: 'M-20 763L58.8792 695L125.301 628.867C127.843 626.336 131.019 624.535 134.497 623.653L280.481 586.639C284.675 585.575 288.412 583.179 291.13 579.812L324.327 538.673C326.786 535.625 328.297 531.922 328.671 528.023L340.49 404.812C341.287 396.505 347.157 389.567 355.217 387.405L508.196 346.362C513.789 344.862 518.456 341.011 520.993 335.805L585.461 203.493C588.081 198.116 592.97 194.194 598.787 192.802L721.753 163.382C722.816 163.128 723.857 162.787 724.865 162.362L879.299 97.2915C884.292 95.1877 888.218 91.1484 890.178 86.0975L1002 -202',
          B: 'M-48 784L48.6945 682.419C52.6988 678.212 58.3339 675.954 64.1351 676.231L202.471 682.84C204.806 682.951 207.142 682.653 209.373 681.958L306.432 651.725C308.79 650.99 311.264 650.699 313.728 650.866L422.861 658.239C431.331 658.811 439.24 653.973 442.588 646.171L512.11 484.151C515.603 476.01 524.036 471.137 532.833 472.175L750.768 497.889C754.198 498.294 757.673 497.804 760.857 496.466L962.055 411.959C963.348 411.416 964.695 411.011 966.072 410.749L1223 362',
        }}
        opacity={.35}
      /> */}

      <TricolorMorphDef
        mobile={{
          R: 'M325.737 112L393 181.629L299 205L325.737 112',
          G: 'M-31.567 76.75L22.5335 170.46L-5.48296 65.9295L-4.51704 65.6706L23.5 170.203V62H24.5V170.203L52.517 65.6706L53.483 65.9295L25.4665 170.46L79.567 76.75L80.433 77.25L26.3316 170.961L102.846 94.4465L103.554 95.1536L27.039 171.668L120.75 117.567L121.25 118.433L27.5402 172.534L132.071 144.517L132.329 145.483L27.7969 173.5H136V174.5H27.7969L132.329 202.517L132.071 203.483L27.5402 175.466L121.25 229.567L120.75 230.433L27.0388 176.332L103.554 252.846L102.846 253.554L26.3317 177.039L80.433 270.75L79.567 271.25L25.4665 177.54L53.483 282.071L52.517 282.329L24.5 177.797V286H23.5V177.797L-4.51704 282.329L-5.48296 282.071L22.5335 177.54L-31.567 271.25L-32.433 270.75L21.6682 177.039L-54.8464 253.554L-55.5535 252.846L20.9614 176.332L-72.75 230.433L-73.25 229.567L20.4597 175.467L-84.0705 203.483L-84.3294 202.517L20.2032 174.5H-88V173.5H20.2031L-84.3294 145.483L-84.0705 144.517L20.4597 172.533L-73.25 118.433L-72.75 117.567L20.9612 171.668L-55.5535 95.1536L-54.8464 94.4465L21.6683 170.961L-32.433 77.25L-31.567 76.75Z',
          B: 'M324 459.5C324 490.152 299.152 515 268.5 515C237.848 515 213 490.152 213 459.5C213 428.848 237.848 404 268.5 404C299.152 404 324 428.848 324 459.5Z',
        }}
        desktop={{
          R: "M878.407 93.0094L983.019 201.425L836.823 237.814L878.407 93.0094Z",
          G: "M-10.567 329.75L43.5335 423.46L15.517 318.929L16.483 318.671L44.5 423.203V315H45.5V423.203L73.517 318.671L74.483 318.929L46.4665 423.46L100.567 329.75L101.433 330.25L47.3316 423.961L123.846 347.446L124.554 348.154L48.039 424.668L141.75 370.567L142.25 371.433L48.5402 425.534L153.071 397.517L153.329 398.483L48.7969 426.5H157V427.5H48.7969L153.329 455.517L153.071 456.483L48.5402 428.466L142.25 482.567L141.75 483.433L48.0388 429.332L124.554 505.846L123.846 506.554L47.3317 430.039L101.433 523.75L100.567 524.25L46.4665 430.54L74.483 535.071L73.517 535.329L45.5 430.797V539H44.5V430.797L16.483 535.329L15.517 535.071L43.5335 430.54L-10.567 524.25L-11.433 523.75L42.6682 430.039L-33.8464 506.554L-34.5535 505.846L41.9614 429.332L-51.75 483.433L-52.25 482.567L41.4597 428.467L-63.0705 456.483L-63.3294 455.517L41.2032 427.5H-67V426.5H41.2031L-63.3294 398.483L-63.0705 397.517L41.4597 425.533L-52.25 371.433L-51.75 370.567L41.9612 424.668L-34.5535 348.154L-33.8464 347.446L42.6683 423.961L-11.433 330.25L-10.567 329.75Z",
          B: "M1173 438.5C1173 482.407 1137.41 518 1093.5 518C1049.59 518 1014 482.407 1014 438.5C1014 394.593 1049.59 359 1093.5 359C1137.41 359 1173 394.593 1173 438.5Z",
        }}
      />
      <DefaultPageHeader
        id="DesignPageHeader"
        title="Design"
      >
        <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>Apart from designing apps and websites, businesses also requires designs for a wide range of purposes. Design covers every aspect of an user's experience with your brand, products and services, and cannot be taken lightly.</Widowless>
          </StandardTextBlock>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>Design coupled with data-driven insights does not only look great: it is crucial in helping the potential customers understanding and retaining what you are trying to sell.</Widowless>
          </StandardTextBlock>
          {
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }
          <GridRowEndColumn>
            <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
            // gaEvent={{ event: "contact-button--hero" }}
            >Contact Us</CTAButton>
          </GridRowEndColumn>
        </GridRow>
      </DefaultPageHeader>

      <PageSection id="DesignPageHashtagLinks" className="DesignPageHashtagLinks">
        <GridRow>
          {DesignHashtags.map(key => <Link
            key={key}
            to={`/design#${key}`}
            title={key}
          >
            <Line />
            <strong>#{key.replace("Design", "")}</strong>
          </Link>)}
          {!isIE && <Line className="u-lg" />}
        </GridRow>
      </PageSection>

      <PageSection id="DesignPageGallerySection" className="DesignPageGallerySection">
        <DesignGalleryDigital />
        <DesignGalleryAnimations />
        <DesignGalleryBranding />
        <DesignGalleryPrint />
      </PageSection>

      <QuoteSection
        id="DesignPageQuoteSection"
        className="DesignPageQuoteSection"
        content="Good design is at the intersection of creativity, insight and technology."
        addLineBefore
        addLineAfter
      // addLineAfter={isBrowser && UI.viewport.width > 768}
      />

      <PageEndCTASection preSelectTopic="design" />
      {/* <ContactForm preSelectedTopics={['design']} /> */}

    </PageTemplate>
  )} />
}

export default PageDesign
