import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { FiaRallyMarshalsTrainingImagesQuery } from "../../types/generated";

export const ProjectMetaInfoFIARallyMarshalsTraining: ProjectMetaInfo = {
  name: "fia-rally-marshals-training",
  displayName: "FIA Rally Marshals Training Module Design",
  abbreviation: "FIA Training Module Design",
  subtitle: "Fédération Internationale de l'Automobile",
  description: "We were really honoured to have been awarded the opportunity to design a full suite of training materials for FIA Rally Marshals. It comprises of a dozen modules and various different formats of learning materials, which was one of the most exciting projects we have ever worked on.",
  categories: [
    Category.design,
  ],
  sectors: [
    Sector.governmentAndNPO,
    Sector.motorsports,
    Sector.eLearning,
  ],
  hasPage: false,
  hashtags: [
    Hashtag.digitalDesign,
    Hashtag.printDesign,
  ],
  keywords: [
    'print design',
    'digital design',
    'eLearning modules',
    'infographics',
  ],
}
export const useFIARallyMarshalsTrainingInfo = () => {
  const FIARallyMarshalsTrainingImages: FiaRallyMarshalsTrainingImagesQuery = useStaticQuery(graphql`
    query FIARallyMarshalsTrainingImages {
      featuredImage: file(relativePath: {eq: "images/fia/fia-flag-navy-and-gold.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/fia-rally-marshals-training/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "FIA Rally Marshals Training Booklet and Swatches design",
      alt: "FIA Rally Marshals Training Booklet and Swatches design",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoFIARallyMarshalsTraining,
    featuredImage: {
      image: FIARallyMarshalsTrainingImages.featuredImage!.childImageSharp,
      title: "FIA Rally Marshals Training",
      alt: "FIA Rally Marshals Training",
    },
    gallery: FIARallyMarshalsTrainingImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info
}