import React from 'react';

export const ICSLogoDefault = <svg width="294" height="86" viewBox="0 0 294 86" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ aspectRatio: '294/86' }}>
  <path d="M76.0401 24.6337V20.9707L72.8369 19.1392L69.6338 20.9707V23.6263L56.3106 31.2271C52.6432 25.5494 46.3298 21.7491 39.0879 21.4285V39.3773L41.409 40.7051V43.3608L57.1462 52.3351C56.8677 52.8388 56.5891 53.3425 56.2642 53.8461H56.3106L69.6338 61.4468V63.0952L44.1015 77.6556L44.9835 78.1593V79.1666L70.5158 64.6062L72.8369 65.934L76.0401 64.1025V60.4395L73.719 59.1117V25.9615L76.0401 24.6337ZM70.4694 25.1373L71.9085 25.9615V59.1117L70.4694 59.9359L57.1462 52.3351C58.7245 49.4047 59.6066 46.0622 59.6066 42.5366C59.6066 39.011 58.7245 35.6685 57.1462 32.7381L70.4694 25.1373Z" fill="black" />
  <path d="M38.1594 46.1996L35.8383 44.8718L20.194 53.8004L19.3119 52.2894L34.9563 43.3608V40.7051L37.2774 39.3773V21.4286C30.0355 21.7033 23.7221 25.5494 20.0547 31.2271L6.73148 23.6264V21.978L35.3276 5.67766L37.2774 6.77656V21.337V21.3828C37.8345 21.3828 38.3451 21.337 38.9022 21.3828C38.9486 21.3828 38.995 21.3828 39.0879 21.3828V6.77656L41.0376 5.67766L66.0592 19.9176V18.9103L66.9413 18.4066L41.409 3.84615V1.8315L38.2058 0L35.0027 1.8315V3.89194L5.84945 20.467L3.52834 19.1392L0.325195 20.9707V24.6337L3.52834 26.4652L5.84945 25.1374L19.1727 32.7381C17.5943 35.6685 16.7123 39.011 16.7123 42.5366C16.7123 46.0623 17.5943 49.4048 19.1727 52.3352L5.84945 59.9359L4.41036 59.1117V30.0366L3.52834 30.5403L2.64631 30.0366V59.1117L0.325195 60.4396V64.1026L3.52834 65.9341L5.84945 64.6062L34.9563 81.1813V83.837L38.1594 85.6685L41.3625 83.837V80.174L39.0414 78.8462V63.6447C38.995 63.6447 38.9486 63.6447 38.9022 63.6447C46.1905 63.4158 52.5503 59.5696 56.2641 53.8462L40.4805 44.8718L38.1594 46.1996ZM37.2774 63.6447V78.8462L35.8383 79.6703L6.73148 63.0952V61.4469L20.0547 53.8462C23.7221 59.5696 30.1283 63.4158 37.4631 63.6447C37.3702 63.6447 37.3238 63.6447 37.2774 63.6447Z" fill="black" />
  <path d="M93.1967 37.893C92.9854 37.893 92.8445 37.755 92.8445 37.5471V24.5553C92.8445 24.3474 92.9854 24.2078 93.1967 24.2078H94.0419C94.2532 24.2078 94.3941 24.3474 94.3941 24.5553V37.5471C94.3941 37.755 94.2532 37.893 94.0419 37.893H93.1967Z" fill="black" />
  <path d="M102.846 37.893H102.001C101.789 37.893 101.648 37.755 101.648 37.5471V24.5553C101.648 24.3474 101.789 24.2078 102.001 24.2078H102.987C103.198 24.2078 103.339 24.3474 103.409 24.4855L110.171 35.115V24.5553C110.171 24.3474 110.312 24.2078 110.453 24.2078H111.298C111.509 24.2078 111.65 24.3474 111.65 24.5553V37.5471C111.65 37.755 111.509 37.893 111.298 37.893H110.593C110.453 37.893 110.312 37.893 110.03 37.5471L103.057 26.7097V37.6154C103.198 37.755 103.057 37.893 102.846 37.893Z" fill="black" />
  <path d="M126.582 27.8901C126.371 27.8901 126.3 27.8203 126.23 27.6125C126.159 26.4321 125.596 25.3898 123.272 25.3898C121.441 25.3898 120.173 25.8753 120.173 27.6823C120.173 31.6422 128.061 29.2101 128.061 34.3504C128.061 37.1299 125.596 38.1722 123.272 38.1722C119.539 38.1722 118.271 35.6021 118.271 34.003C118.271 33.7951 118.412 33.657 118.623 33.657H119.468C119.68 33.657 119.75 33.7951 119.821 34.003C119.821 34.8374 120.454 36.8523 123.342 36.8523C125.173 36.8523 126.582 36.1574 126.582 34.4202C126.582 30.8077 118.694 33.1002 118.694 27.7521C118.694 25.2502 120.595 24.1396 123.272 24.1396C126.793 24.1396 127.709 26.2925 127.709 27.6823C127.709 27.8901 127.568 28.0297 127.357 28.0297H126.582V27.8901Z" fill="black" />
  <path d="M133.274 24.5553C133.274 24.3474 133.414 24.2078 133.626 24.2078H143.416C143.627 24.2078 143.768 24.3474 143.768 24.5553V25.2501C143.768 25.4595 143.627 25.5976 143.416 25.5976H139.26V37.5471C139.26 37.755 139.119 37.893 138.908 37.893H138.063C137.852 37.893 137.711 37.755 137.711 37.5471V25.5976H133.555C133.344 25.5976 133.203 25.4595 133.203 25.2501V24.5553H133.274Z" fill="black" />
  <path d="M150.178 37.893C149.967 37.893 149.826 37.755 149.826 37.5471V24.5553C149.826 24.3474 149.967 24.2078 150.178 24.2078H151.023C151.234 24.2078 151.375 24.3474 151.375 24.5553V37.5471C151.375 37.755 151.234 37.893 151.023 37.893H150.178Z" fill="black" />
  <path d="M157.432 24.5553C157.432 24.3474 157.573 24.2078 157.784 24.2078H167.574C167.785 24.2078 167.926 24.3474 167.926 24.5553V25.2501C167.926 25.4595 167.785 25.5976 167.574 25.5976H163.419V37.5471C163.419 37.755 163.278 37.893 163.066 37.893H162.221C162.01 37.893 161.869 37.755 161.869 37.5471V25.5976H157.714C157.502 25.5976 157.361 25.4595 157.361 25.2501V24.5553H157.432Z" fill="black" />
  <path d="M183.14 24.2078C183.351 24.2078 183.492 24.3474 183.492 24.5553V32.8225C183.492 36.0876 181.52 38.1024 178.632 38.1024C175.815 38.1024 173.772 36.0876 173.772 32.8225V24.5553C173.772 24.3474 173.913 24.2078 174.054 24.2078H174.899C175.11 24.2078 175.251 24.3474 175.251 24.5553V32.8225C175.251 35.3229 176.66 36.7126 178.632 36.7126C180.604 36.7126 182.013 35.3229 182.013 32.8225V24.5553C182.013 24.3474 182.154 24.2078 182.365 24.2078H183.14Z" fill="black" />
  <path d="M189.338 24.5553C189.338 24.3474 189.479 24.2078 189.69 24.2078H199.48C199.691 24.2078 199.832 24.3474 199.832 24.5553V25.2501C199.832 25.4595 199.691 25.5976 199.48 25.5976H195.324V37.5471C195.324 37.755 195.184 37.893 194.972 37.893H194.127C193.916 37.893 193.775 37.755 193.775 37.5471V25.5976H189.619C189.408 25.5976 189.267 25.4595 189.267 25.2501V24.5553H189.338Z" fill="black" />
  <path d="M214.481 25.3199C214.481 25.5278 214.34 25.6674 214.129 25.6674H207.438V30.3222H212.791C213.002 30.3222 213.143 30.4602 213.143 30.6696V31.363C213.143 31.5724 213.002 31.7104 212.791 31.7104H207.438V36.5048H214.129C214.34 36.5048 214.481 36.6429 214.481 36.8522V37.6154C214.481 37.8248 214.34 37.9628 214.129 37.9628H206.241C206.029 37.9628 205.888 37.8248 205.888 37.6154V24.6251C205.888 24.4172 206.029 24.2776 206.241 24.2776H214.129C214.34 24.2776 214.481 24.4172 214.481 24.6251V25.3199Z" fill="black" />
  <path d="M229.062 29.3497C229.062 26.0846 231.246 24 234.133 24C237.021 24 239.204 26.0846 239.204 29.3497V32.7528C239.204 35.948 237.021 38.0327 234.133 38.0327C231.246 38.0327 228.992 35.948 228.992 32.7528V29.3497H229.062ZM230.541 32.7528C230.541 35.2546 232.161 36.7127 234.133 36.7127C236.105 36.7127 237.725 35.2546 237.725 32.7528V29.3497C237.725 26.8478 236.105 25.3898 234.133 25.3898C232.161 25.3898 230.541 26.8478 230.541 29.3497V32.7528Z" fill="black" />
  <path d="M254.628 25.3199C254.628 25.5278 254.487 25.6674 254.276 25.6674H247.585V30.7379H252.938C253.149 30.7379 253.29 30.8775 253.29 31.0853V31.7802C253.29 31.9896 253.149 32.1277 252.938 32.1277H247.585V37.6154C247.585 37.8248 247.444 37.9628 247.233 37.9628H246.388C246.176 37.9628 246.035 37.8248 246.035 37.6154V24.6251C246.035 24.4172 246.176 24.2776 246.388 24.2776H254.276C254.487 24.2776 254.628 24.4172 254.628 24.6251V25.3199Z" fill="black" />
  <path d="M102.283 56.8597C102.565 56.8597 102.706 57.1373 102.706 57.3452C102.494 59.6377 100.593 61.9302 97.4937 61.9302C94.3947 61.9302 92 59.6377 92 56.2331V53.5931C92 50.1901 94.3243 47.8976 97.4937 47.8976C100.663 47.8976 102.565 50.1203 102.706 52.4826C102.706 52.7602 102.565 52.9681 102.283 52.9681H100.241C100.029 52.9681 99.818 52.83 99.7476 52.4128C99.6067 51.51 98.9024 50.5375 97.4937 50.5375C96.0851 50.5375 94.8877 51.6481 94.8877 53.6629V56.2331C94.8877 58.248 96.0851 59.2903 97.4937 59.2903C98.9024 59.2903 99.6771 58.3875 99.7476 57.4848C99.818 57.2056 99.8884 56.8597 100.241 56.8597H102.283Z" fill="black" />
  <path d="M112.425 61.7906C112.073 61.7906 111.792 61.513 111.792 61.1655V56.7201L107.636 49.2175C107.566 49.0779 107.354 48.8003 107.354 48.5226C107.354 48.245 107.566 48.1054 107.777 48.1054H109.89C110.031 48.1054 110.242 48.1752 110.383 48.4528L113.271 54.0104L116.299 48.3831C116.37 48.245 116.51 48.0371 116.792 48.0371H118.764C119.046 48.0371 119.187 48.245 119.187 48.4528C119.187 48.6622 118.976 49.0097 118.835 49.2175L114.609 56.6503V61.0973C114.609 61.4447 114.327 61.7224 113.975 61.7224H112.425V61.7906Z" fill="black" />
  <path d="M124.821 61.1655V48.7305C124.821 48.3831 125.103 48.1054 125.455 48.1054H130.034C132.78 48.1054 134.612 49.3556 134.612 51.9273V52.2049C134.612 53.3853 133.978 54.3578 132.921 54.8448C134.259 55.4699 134.893 56.5805 134.893 57.7624V57.9703C134.893 61.1655 132.499 61.7906 129.752 61.7906H125.455C125.103 61.7906 124.821 61.513 124.821 61.1655ZM127.639 53.5249H129.752C130.738 53.5249 131.653 53.4551 131.653 52.2747V52.0653C131.653 50.8151 130.738 50.7453 129.752 50.7453H127.639V53.5249ZM127.639 59.0824H130.034C131.301 59.0824 132.006 58.735 132.006 57.6926V57.5531C132.006 56.5805 131.231 56.1648 130.034 56.1648H127.709V59.0824H127.639Z" fill="black" />
  <path d="M149.473 50.1203C149.473 50.4677 149.191 50.7453 148.839 50.7453H143.557V53.5249H147.642C147.994 53.5249 148.276 53.8025 148.276 54.15V55.608C148.276 55.9554 147.994 56.2331 147.642 56.2331H143.557V59.1522H148.839C149.191 59.1522 149.473 59.4299 149.473 59.7075V61.1655C149.473 61.513 149.191 61.7906 148.839 61.7906H141.303C140.951 61.7906 140.669 61.513 140.669 61.1655V48.7305C140.669 48.3831 140.951 48.1054 141.303 48.1054H148.839C149.191 48.1054 149.473 48.3831 149.473 48.7305V50.1203Z" fill="black" />
  <path d="M158.206 61.1655C158.206 61.513 157.924 61.7906 157.572 61.7906H155.952C155.6 61.7906 155.318 61.513 155.318 61.1655V48.7305C155.318 48.3831 155.6 48.1054 155.952 48.1054H161.305C163.77 48.1054 165.601 49.4952 165.601 52.2049V52.7602C165.601 54.5672 164.897 55.8872 163.136 56.5123L165.39 60.7498C165.46 60.9577 165.601 61.0973 165.601 61.3051C165.601 61.5828 165.39 61.7223 165.179 61.7223H163.207C162.855 61.7223 162.643 61.513 162.573 61.2353L160.46 56.9978H158.347V61.1655H158.206ZM158.206 54.3578H161.023C161.939 54.3578 162.643 53.8723 162.643 52.6904V52.4128C162.643 51.1626 161.939 50.7453 161.023 50.7453H158.206V54.3578Z" fill="black" />
  <path d="M187.084 52.2747C186.732 52.2747 186.521 52.0653 186.45 51.7179C186.239 51.0928 185.816 50.3281 184.549 50.3281C183.281 50.3281 182.436 50.6073 182.436 51.6481C182.436 54.15 189.479 52.4826 189.479 57.8322C189.479 60.8181 186.943 61.8604 184.408 61.8604C180.816 61.8604 179.266 59.4981 179.266 57.6926C179.266 57.3452 179.548 57.0675 179.9 57.0675H181.52C181.872 57.0675 182.013 57.3452 182.084 57.6229C182.154 57.9703 182.365 59.4298 184.478 59.4298C186.028 59.4298 186.662 59.0126 186.662 57.8322C186.662 55.1225 179.618 56.7899 179.618 51.6481C179.618 49.3556 181.168 47.8278 184.619 47.8278C187.718 47.8278 189.338 49.6348 189.338 51.5798C189.338 51.9273 189.056 52.2049 188.704 52.2049H187.084V52.2747Z" fill="black" />
  <path d="M204.269 50.1203C204.269 50.4677 203.987 50.7453 203.635 50.7453H198.353V53.5249H202.438C202.79 53.5249 203.072 53.8025 203.072 54.15V55.608C203.072 55.9554 202.79 56.2331 202.438 56.2331H198.353V59.1522H203.635C203.987 59.1522 204.269 59.4299 204.269 59.7075V61.1655C204.269 61.513 203.987 61.7906 203.635 61.7906H196.099C195.747 61.7906 195.465 61.513 195.465 61.1655V48.7305C195.465 48.3831 195.747 48.1054 196.099 48.1054H203.635C203.987 48.1054 204.269 48.3831 204.269 48.7305V50.1203Z" fill="black" />
  <path d="M219.693 56.8597C219.975 56.8597 220.116 57.1373 220.116 57.3452C219.905 59.6377 218.003 61.9302 214.904 61.9302C211.735 61.9302 209.41 59.6377 209.41 56.2331V53.5931C209.41 50.1901 211.735 47.8976 214.904 47.8976C218.073 47.8976 219.975 50.1203 220.116 52.4826C220.116 52.7602 219.975 52.9681 219.693 52.9681H217.651C217.44 52.9681 217.228 52.83 217.158 52.4128C217.017 51.51 216.313 50.5375 214.904 50.5375C213.425 50.5375 212.298 51.6481 212.298 53.6629V56.2331C212.298 58.248 213.495 59.2903 214.904 59.2903C216.383 59.2903 217.087 58.3875 217.158 57.4848C217.228 57.2056 217.299 56.8597 217.651 56.8597H219.693Z" fill="black" />
  <path d="M235.612 48.1054C235.964 48.1054 236.246 48.3831 236.246 48.7305V56.4425C236.246 59.8456 234.133 62 230.963 62C227.864 62 225.751 59.9154 225.751 56.4425V48.7305C225.751 48.3831 226.033 48.1054 226.385 48.1054H228.005C228.357 48.1054 228.639 48.3831 228.639 48.7305V56.4425C228.639 58.4573 229.555 59.2903 230.963 59.2903C232.372 59.2903 233.358 58.4573 233.358 56.4425V48.7305C233.358 48.3831 233.64 48.1054 233.992 48.1054H235.612Z" fill="black" />
  <path d="M245.261 61.1655C245.261 61.513 244.979 61.7906 244.627 61.7906H243.006C242.654 61.7906 242.372 61.513 242.372 61.1655V48.7305C242.372 48.3831 242.654 48.1054 243.006 48.1054H248.359C250.824 48.1054 252.656 49.4952 252.656 52.2049V52.7602C252.656 54.5672 251.952 55.8872 250.19 56.5123L252.445 60.7498C252.515 60.9577 252.656 61.0973 252.656 61.3051C252.656 61.5828 252.445 61.7223 252.232 61.7223H250.262C249.91 61.7223 249.697 61.513 249.627 61.2353L247.514 56.9978H245.4V61.1655H245.261ZM245.261 54.3578H248.078C248.993 54.3578 249.697 53.8723 249.697 52.6904V52.4128C249.697 51.1626 248.993 50.7453 248.078 50.7453H245.261V54.3578Z" fill="black" />
  <path d="M259.066 61.7906C258.714 61.7906 258.432 61.513 258.432 61.1655V48.7305C258.432 48.3831 258.714 48.1054 259.066 48.1054H260.686C261.038 48.1054 261.32 48.3831 261.32 48.7305V61.1655C261.32 61.513 261.038 61.7906 260.686 61.7906H259.066Z" fill="black" />
  <path d="M266.672 48.7305C266.672 48.3831 266.954 48.1054 267.306 48.1054H276.744C277.096 48.1054 277.379 48.3831 277.379 48.7305V50.1901C277.379 50.5375 277.096 50.8151 276.744 50.8151H273.434V61.2353C273.434 61.5828 273.152 61.8604 272.8 61.8604H271.181C270.827 61.8604 270.547 61.5828 270.547 61.2353V50.8151H267.236C266.884 50.8151 266.602 50.5375 266.602 50.1901V48.7305H266.672Z" fill="black" />
  <path d="M286.957 61.7906C286.605 61.7906 286.322 61.513 286.322 61.1655V56.7201L282.167 49.2175C282.097 49.0779 281.885 48.8003 281.885 48.5226C281.885 48.245 282.097 48.1054 282.308 48.1054H284.421C284.561 48.1054 284.773 48.1752 284.913 48.4528L287.802 54.0104L290.83 48.3831C290.9 48.245 291.041 48.0371 291.323 48.0371H293.296C293.576 48.0371 293.717 48.245 293.717 48.4528C293.717 48.6622 293.506 49.0097 293.365 49.2175L289.14 56.6503V61.0973C289.14 61.4447 288.858 61.7224 288.506 61.7224H286.957V61.7906Z" fill="black" />
</svg>
