import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { isBuildTime } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { supportsWebp } from '../../utils/image.utils';
import { useStore } from '../../utils/mobx.utils';
import { isArray } from '../../utils/typeChecks.utils';
import { observeVisibility } from '../../utils/visibilityObserver.util';
import './NativeVideoWrapper.scss';

type NativeVideoWrapperProps = {
  id: string,
  className?: string,
  loop?: boolean,
  muted?: boolean,
  poster?: string,
  posterWebp?: string,
  autoPlay?: boolean,
  controls?: boolean,
  preload?: HTMLVideoElement['preload'],
  playsInline?: boolean,
  width?: number,
  height?: number,
  src?: string | string[],
  onlyLoadWhenVisible?: boolean,
  rounded?: boolean,
}

const NativeVideoWrapper: React.FC<NativeVideoWrapperProps> = props => {
  const ref = useObservableRef();
  const videoRef = useObservableRef<HTMLVideoElement>();
  // const p = useProps(props);
  const s = useStore(() => ({
    src: props.onlyLoadWhenVisible ? [''] : isArray(props.src) ? props.src : [props.src],
  }))
  useOnMount(() => {
    if (isBuildTime) return;
    const d = makeDisposerController();
    const setSrcAndPlay = action(() => {
      s.src = isArray(props.src) ? props.src : [props.src];
      videoRef?.current?.play();
    })
    d.add(observeVisibility(ref, {
      id: props.id,
      onBecomeVisible: setSrcAndPlay,
      onBecomeInvisible: () => {
        videoRef?.current?.pause();
      }
    }))
    if (!props.onlyLoadWhenVisible) { 
      setSrcAndPlay();
    }
    return d.disposer;
  })
  return <Observer children={() => (
    <div
      id={props.id}
      className={joinClassName('NativeVideoWrapper', props.className, props.rounded && 'rounded')}
      ref={ref}
    >
      <video  
        loop={props.loop ?? true}
        muted={props.muted ?? true}
        poster={props.posterWebp && supportsWebp ? props.posterWebp : props.poster}
        preload={props.preload ?? 'auto'}
        width={props.width}
        height={props.height}
        autoPlay={props.autoPlay ?? (props.onlyLoadWhenVisible ? true : false)}
        controls={props.controls ?? false}
        playsInline={props.playsInline ?? true}
      >
        {s.src && s.src.map((srcString, i) => <source src={srcString} key={i}/>)}
      </video>
    </div>
  )} />
}

export default NativeVideoWrapper;