import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { SoluColImagesQuery } from "../../types/generated";

export const ProjectMetaInfoSoluCol: ProjectMetaInfo = {
  name: "solucol",
  displayName: "SoluCol",
  abbreviation: "SoluCol",
  subtitle: "Branding and Packaging Design",
  // TODO
  description: "",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
  ],
  hasPage: false,
  hashtags: [
    Hashtag.branding,
    Hashtag.printDesign,
  ],
  keywords: [
    'branding design',
    'print design',
    'packaging design',
    'beauty products',
  ],
}
export const useSoluColInfo = () => {
  const SoluColImages: SoluColImagesQuery = useStaticQuery(graphql`
    query SoluColImages {
      featuredImage: file(relativePath: {eq: "images/solucol/solucol-packaging-design-3d-mockup.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
          relativeDirectory: {eq: "images/solucol/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'SoluCol Packaging 3D Rendering',
      alt: 'SoluCol packaging 3D rendering on a kitchen table',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoSoluCol,
    featuredImage: {
      image: SoluColImages.featuredImage!.childImageSharp!,
      title: 'SoluCol Design overview: branding, iconography and packaging',
      alt: 'SoluCol design overview: branding, iconography and packaging',
    },
    gallery: SoluColImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}