import React from 'react';

type GridRowEndColumnProps = {}

const GridRowEndColumn: React.FC<GridRowEndColumnProps> = props => {
  return <div className="GridRowEndColumn">
    {props.children}
  </div>
}

export default GridRowEndColumn;