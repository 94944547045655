import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Hashtag } from '../../constants/hashtags.constants';
import GridRow from '../GridRow/GridRow';
import YouTubeEmbed from '../YouTubeEmbed/YouTubeEmbed';
import './DesignGalleryAnimations.scss';

type DesignGalleryAnimationsProps = {}

const DesignGalleryAnimations: React.FC<DesignGalleryAnimationsProps> = props => {
  return <Observer children={() => (
    <div className="DesignGallery DesignGalleryAnimations" id={Hashtag.animations}>
      <header className="DesignGalleryHeader">
        <h2>#Animations</h2>
      </header>
      <GridRow>
        <YouTubeEmbed
          src="https://www.youtube.com/embed/AKHuyMqV0fg"
          title="AAA IDP Introduction Video"
        />
        <YouTubeEmbed
          src="https://www.youtube.com/embed/CW5v09c5DYc"
          title="TBM Solution: Conquering Europe Animated Advert"
        />
        <YouTubeEmbed
          src="https://www.youtube.com/embed/mTbAPG6WIWo"
          title="Alexander Boyd Displays: Animated Advert"
        />
        {/* <YouTubeEmbed
          src="https://www.youtube.com/embed/l1UkdY4yfZ4"
          title="Viralbamboo Showreel 2017"
        /> */}
        <YouTubeEmbed
          src="https://www.youtube.com/embed/D2Bn1TBBg74"
          title="Urgent.ie Promotional Video"
        />
      </GridRow>
    </div>
  )} />
}

export default DesignGalleryAnimations;