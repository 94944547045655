import React from 'react';

export const XMusicLogoDefault = <svg width="358" height="85" viewBox="0 0 358 85" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ aspectRatio: '358 / 85' }}>
  <path d="M108.286 65.1759C108.286 64.8091 108.58 64.5118 108.942 64.5118H110.255C110.617 64.5118 110.911 64.8091 110.911 65.1759V68.8282C110.911 69.195 111.205 69.4923 111.567 69.4923L119.771 69.4923C120.133 69.4923 120.427 69.195 120.427 68.8282V65.1759C120.427 64.8091 120.721 64.5118 121.083 64.5118H122.396C122.758 64.5118 123.052 64.8091 123.052 65.1759V76.1329C123.052 76.4997 122.758 76.797 122.396 76.797H121.083C120.721 76.797 120.427 76.4997 120.427 76.1329V72.4806C120.427 72.1138 120.133 71.8165 119.771 71.8165H111.567C111.205 71.8165 110.911 72.1138 110.911 72.4806V76.1329C110.911 76.4997 110.617 76.797 110.255 76.797H108.942C108.58 76.797 108.286 76.4997 108.286 76.1329V65.1759Z" fill="black" />
  <path d="M142.084 66.8361C142.447 66.8361 142.741 66.5387 142.741 66.172V65.1759C142.741 64.8091 142.447 64.5118 142.084 64.5118H128.631C128.268 64.5118 127.974 64.8091 127.974 65.1759V76.1329C127.974 76.4997 128.268 76.797 128.631 76.797H142.084C142.447 76.797 142.741 76.4997 142.741 76.1329V75.1368C142.741 74.7701 142.447 74.4728 142.084 74.4728H131.256C130.893 74.4728 130.599 74.1755 130.599 73.8087V72.4806C130.599 72.1138 130.893 71.8165 131.256 71.8165H142.084C142.447 71.8165 142.741 71.5192 142.741 71.1525V70.1564C142.741 69.7896 142.447 69.4923 142.084 69.4923H131.256C130.893 69.4923 130.599 69.195 130.599 68.8282V67.5001C130.599 67.1334 130.893 66.8361 131.256 66.8361H142.084Z" fill="black" />
  <path d="M103.692 66.8361C104.054 66.8361 104.348 66.5387 104.348 66.172V65.1759C104.348 64.8091 104.054 64.5118 103.692 64.5118H90.2383C89.8759 64.5118 89.582 64.8091 89.582 65.1759V66.172C89.582 66.5387 89.8759 66.8361 90.2383 66.8361H95.1604C95.5229 66.8361 95.8167 67.1334 95.8167 67.5001V76.1329C95.8167 76.4997 96.1105 76.797 96.473 76.797H97.7855C98.148 76.797 98.4418 76.4997 98.4418 76.1329V67.5001C98.4418 67.1334 98.7356 66.8361 99.0981 66.8361H103.692Z" fill="black" />
  <path d="M258.507 64.5118C259.09 64.5118 259.384 65.2247 258.973 65.6438L254.515 70.1866C254.261 70.4455 254.261 70.8633 254.515 71.1223L258.973 75.6651C259.384 76.0841 259.09 76.797 258.507 76.797H256.867C256.692 76.797 256.525 76.7264 256.402 76.6008L252.674 72.8013C252.417 72.5397 251.999 72.5397 251.742 72.8013L248.014 76.6008C247.891 76.7264 247.723 76.797 247.549 76.797H245.909C245.325 76.797 245.032 76.0841 245.443 75.6651L249.9 71.1223C250.154 70.8633 250.154 70.4455 249.9 70.1866L245.443 65.6438C245.032 65.2247 245.325 64.5118 245.909 64.5118H247.549C247.723 64.5118 247.891 64.5825 248.014 64.708L251.742 68.5075C251.999 68.7691 252.417 68.7691 252.674 68.5075L256.402 64.708C256.525 64.5825 256.692 64.5118 256.867 64.5118H258.507Z" fill="black" />
  <path d="M298.476 66.8361C298.838 66.8361 299.132 66.5387 299.132 66.172V65.1759C299.132 64.8091 298.838 64.5118 298.476 64.5118H285.022C284.659 64.5118 284.366 64.8091 284.366 65.1759V76.1329C284.366 76.4997 284.659 76.797 285.022 76.797H298.476C298.838 76.797 299.132 76.4997 299.132 76.1329V75.1368C299.132 74.7701 298.838 74.4728 298.476 74.4728H287.647C287.285 74.4728 286.991 74.1755 286.991 73.8087V72.4806C286.991 72.1138 287.285 71.8165 287.647 71.8165H298.476C298.838 71.8165 299.132 71.5192 299.132 71.1525V70.1564C299.132 69.7896 298.838 69.4923 298.476 69.4923H287.647C287.285 69.4923 286.991 69.195 286.991 68.8282V67.5001C286.991 67.1334 287.285 66.8361 287.647 66.8361H298.476Z" fill="black" />
  <path d="M337.524 66.8361C337.887 66.8361 338.18 66.5387 338.18 66.172V65.1759C338.18 64.8091 337.887 64.5118 337.524 64.5118H324.07C323.708 64.5118 323.414 64.8091 323.414 65.1759V66.172C323.414 66.5387 323.708 66.8361 324.07 66.8361H328.993C329.355 66.8361 329.649 67.1334 329.649 67.5001V76.1329C329.649 76.4997 329.943 76.797 330.305 76.797H331.618C331.98 76.797 332.274 76.4997 332.274 76.1329V67.5001C332.274 67.1334 332.568 66.8361 332.93 66.8361H337.524Z" fill="black" />
  <path d="M356.884 64.5118C357.247 64.5118 357.541 64.8091 357.541 65.1759V66.172C357.541 66.5387 357.247 66.8361 356.884 66.8361H346.056C345.693 66.8361 345.4 67.1334 345.4 67.5001V68.8282C345.4 69.195 345.693 69.4923 346.056 69.4923H356.884C357.247 69.4923 357.541 69.7896 357.541 70.1564V76.1329C357.541 76.4997 357.247 76.797 356.884 76.797H343.431C343.068 76.797 342.774 76.4997 342.774 76.1329V75.1368C342.774 74.7701 343.068 74.4728 343.431 74.4728H354.259C354.622 74.4728 354.916 74.1755 354.916 73.8087V72.4806C354.916 72.1138 354.622 71.8165 354.259 71.8165H343.431C343.068 71.8165 342.774 71.5192 342.774 71.1525V65.1759C342.774 64.8091 343.068 64.5118 343.431 64.5118H356.884Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M279.443 65.1759C279.443 64.8091 279.15 64.5118 278.787 64.5118H265.334C264.971 64.5118 264.677 64.8091 264.677 65.1759V76.1329C264.677 76.4997 264.971 76.797 265.334 76.797H266.646C267.009 76.797 267.302 76.4997 267.302 76.1329V72.4806C267.302 72.1138 267.596 71.8165 267.959 71.8165H278.787C279.15 71.8165 279.443 71.5192 279.443 71.1525V65.1759ZM276.162 69.4923C276.525 69.4923 276.818 69.195 276.818 68.8282V67.5001C276.818 67.1334 276.525 66.8361 276.162 66.8361H267.959C267.596 66.8361 267.302 67.1334 267.302 67.5001V68.8282C267.302 69.195 267.596 69.4923 267.959 69.4923H276.162Z" fill="black" />
  <path fillRule="evenodd" clipRule="evenodd" d="M318.164 64.5118C318.526 64.5118 318.82 64.8091 318.82 65.1759V71.1525C318.82 71.5192 318.526 71.8165 318.164 71.8165H316.195C315.711 71.8165 315.468 72.4091 315.811 72.7556L318.684 75.6634C319.098 76.0817 318.805 76.797 318.22 76.797H316.467C316.293 76.797 316.126 76.727 316.003 76.6025L311.465 72.011C311.342 71.8865 311.175 71.8165 311.001 71.8165H307.335C306.973 71.8165 306.679 72.1138 306.679 72.4806V76.1329C306.679 76.4997 306.385 76.797 306.023 76.797H304.71C304.348 76.797 304.054 76.4997 304.054 76.1329V65.1759C304.054 64.8091 304.348 64.5118 304.71 64.5118H318.164ZM315.539 66.8361C315.901 66.8361 316.195 67.1334 316.195 67.5001V68.8282C316.195 69.195 315.901 69.4923 315.539 69.4923H307.335C306.973 69.4923 306.679 69.195 306.679 68.8282V67.5001C306.679 67.1334 306.973 66.8361 307.335 66.8361H315.539Z" fill="black" />
  <path d="M152.913 65.1759C152.913 64.8091 153.207 64.5118 153.569 64.5118H155.266C155.44 64.5118 155.607 64.5818 155.73 64.7063L159.996 69.0227C160.252 69.2821 160.668 69.2821 160.924 69.0227L165.19 64.7063C165.313 64.5818 165.48 64.5118 165.654 64.5118H167.351C167.714 64.5118 168.007 64.8091 168.007 65.1759V76.1329C168.007 76.4997 167.714 76.797 167.351 76.797H166.039C165.676 76.797 165.382 76.4997 165.382 76.1329V69.7674C165.382 69.1757 164.675 68.8795 164.262 69.2978L160.924 72.6751C160.668 72.9344 160.252 72.9344 159.996 72.6751L156.658 69.2978C156.245 68.8795 155.538 69.1757 155.538 69.7674V76.1329C155.538 76.4997 155.244 76.797 154.882 76.797H153.569C153.207 76.797 152.913 76.4997 152.913 76.1329V65.1759Z" fill="black" />
  <path d="M172.929 65.1759C172.929 64.8091 173.223 64.5118 173.586 64.5118H174.898C175.261 64.5118 175.555 64.8091 175.555 65.1759V73.8087C175.555 74.1755 175.848 74.4728 176.211 74.4728L184.414 74.4728C184.777 74.4728 185.071 74.1755 185.071 73.8087V65.1759C185.071 64.8091 185.364 64.5118 185.727 64.5118H187.039C187.402 64.5118 187.696 64.8091 187.696 65.1759V76.1329C187.696 76.4997 187.402 76.797 187.039 76.797H173.586C173.223 76.797 172.929 76.4997 172.929 76.1329V65.1759Z" fill="black" />
  <path d="M222.489 71.1223C222.235 70.8633 222.235 70.4455 222.489 70.1866L225.584 67.0323C225.707 66.9067 225.875 66.8361 226.05 66.8361H233.635C233.998 66.8361 234.291 66.5387 234.291 66.172V65.1759C234.291 64.8091 233.998 64.5118 233.635 64.5118H224.841C224.666 64.5118 224.498 64.5825 224.375 64.708L219 70.1866C218.746 70.4455 218.746 70.8633 219 71.1223L224.375 76.6008C224.498 76.7264 224.666 76.797 224.841 76.797H233.635C233.998 76.797 234.291 76.4997 234.291 76.1329V75.1368C234.291 74.7701 233.998 74.4728 233.635 74.4728H226.05C225.875 74.4728 225.707 74.4021 225.584 74.2766L222.489 71.1223Z" fill="black" />
  <path d="M206.728 64.5118C207.09 64.5118 207.384 64.8091 207.384 65.1759V66.172C207.384 66.5387 207.09 66.8361 206.728 66.8361H195.899C195.537 66.8361 195.243 67.1334 195.243 67.5001V68.8282C195.243 69.195 195.537 69.4923 195.899 69.4923H206.728C207.09 69.4923 207.384 69.7896 207.384 70.1564V76.1329C207.384 76.4997 207.09 76.797 206.728 76.797H193.274C192.912 76.797 192.618 76.4997 192.618 76.1329V75.1368C192.618 74.7701 192.912 74.4728 193.274 74.4728H204.103C204.465 74.4728 204.759 74.1755 204.759 73.8087V72.4806C204.759 72.1138 204.465 71.8165 204.103 71.8165H193.274C192.912 71.8165 192.618 71.5192 192.618 71.1525V65.1759C192.618 64.8091 192.912 64.5118 193.274 64.5118H206.728Z" fill="black" />
  <path d="M212.306 65.1759C212.306 64.8091 212.6 64.5118 212.962 64.5118H214.275C214.637 64.5118 214.931 64.8091 214.931 65.1759V76.1329C214.931 76.4997 214.637 76.797 214.275 76.797H212.962C212.6 76.797 212.306 76.4997 212.306 76.1329V65.1759Z" fill="black" />
  <path d="M89.9102 12.9492C89.9102 12.0323 90.6447 11.2891 91.5509 11.2891H97.8363C98.2714 11.2891 98.6887 11.464 98.9964 11.7753L113.491 26.4423C114.132 27.0906 115.171 27.0906 115.812 26.4423L130.307 11.7753C130.614 11.464 131.032 11.2891 131.467 11.2891H137.752C138.658 11.2891 139.393 12.0323 139.393 12.9492V49.9024C139.393 50.8193 138.658 51.5626 137.752 51.5626H132.428C131.522 51.5626 130.787 50.8193 130.787 49.9024V27.2702C130.787 25.7912 129.02 25.0505 127.986 26.0963L115.812 38.4155C115.171 39.0638 114.132 39.0638 113.491 38.4155L101.317 26.0963C100.283 25.0505 98.5159 25.7912 98.5159 27.2702V49.9024C98.5159 50.8193 97.7813 51.5626 96.8752 51.5626H91.5509C90.6447 51.5626 89.9102 50.8193 89.9102 49.9024V12.9492Z" fill="black" />
  <path d="M156.185 12.9492C156.185 12.0323 156.92 11.2891 157.826 11.2891H163.15C164.056 11.2891 164.791 12.0323 164.791 12.9492V42.2831C164.791 43.2 165.525 43.9433 166.431 43.9433L194.346 43.9433C195.252 43.9433 195.986 43.2 195.986 42.2831V12.9492C195.986 12.0323 196.721 11.2891 197.627 11.2891H202.952C203.858 11.2891 204.592 12.0323 204.592 12.9492V49.9024C204.592 50.8193 203.858 51.5626 202.951 51.5626H157.826C156.92 51.5626 156.185 50.8193 156.185 49.9024V12.9492Z" fill="black" />
  <path d="M318.952 32.5955C318.317 31.9481 318.317 30.9035 318.952 30.2562L329.604 19.3989C329.912 19.0849 330.332 18.9084 330.769 18.9084L356.359 18.9084C357.265 18.9084 358 18.1651 358 17.2482V12.9492C358 12.0323 357.265 11.2891 356.359 11.2891L326.806 11.2891C326.369 11.2891 325.95 11.4656 325.642 11.7796L307.513 30.2562C306.878 30.9035 306.878 31.9481 307.513 32.5955L325.642 51.0721C325.95 51.386 326.369 51.5626 326.806 51.5626H356.359C357.265 51.5626 358 50.8193 358 49.9024V45.6034C358 44.6866 357.265 43.9433 356.359 43.9433L330.769 43.9433C330.332 43.9433 329.912 43.7667 329.604 43.4528L318.952 32.5955Z" fill="black" />
  <path d="M267.494 11.2891C268.4 11.2891 269.135 12.0323 269.135 12.9492V17.2482C269.135 18.1651 268.4 18.9084 267.494 18.9084L230.974 18.9084C230.068 18.9084 229.334 19.6517 229.334 20.5685V25.956C229.334 26.8729 230.068 27.6162 230.974 27.6162L267.494 27.6162C268.4 27.6162 269.135 28.3594 269.135 29.2763V49.9024C269.135 50.8193 268.4 51.5626 267.494 51.5626L222.369 51.5626C221.462 51.5626 220.728 50.8193 220.728 49.9024V45.6034C220.728 44.6865 221.462 43.9433 222.369 43.9433L258.889 43.9433C259.795 43.9433 260.529 43.2 260.529 42.2831V36.8956C260.529 35.9788 259.795 35.2355 258.889 35.2355L222.369 35.2355C221.462 35.2355 220.728 34.4922 220.728 33.5753V12.9492C220.728 12.0323 221.462 11.2891 222.369 11.2891L267.494 11.2891Z" fill="black" />
  <path d="M285.599 12.9492C285.599 12.0323 286.334 11.2891 287.24 11.2891H292.564C293.47 11.2891 294.205 12.0323 294.205 12.9492V49.9024C294.205 50.8193 293.47 51.5626 292.564 51.5626H287.24C286.334 51.5626 285.599 50.8193 285.599 49.9024V12.9492Z" fill="black" />
  <path d="M75.174 0.309875H57.5336L35.8591 29.4374L18.322 7.44973H0.681647L58.5729 84.798H80.014L44.9852 40.8795L75.174 0.309875Z" fill="url(#VPWFBM0_radial)" />
  <path d="M0.324219 77.1914L26.3437 42.2248L32.3336 57.8814L17.9646 77.1914H0.324219Z" fill="url(#VPWFBM1_radial)" />
  <defs>
    <radialGradient id="VPWFBM0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.223 84.798) rotate(-117.898) scale(100.239 92.4609)">
      <stop stopColor="#2E4FFF" />
      <stop offset="1" stopColor="#0047FF" />
    </radialGradient>
    <radialGradient id="VPWFBM1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.223 84.798) rotate(-117.898) scale(100.239 92.4609)">
      <stop stopColor="#2E4FFF" />
      <stop offset="1" stopColor="#0047FF" />
    </radialGradient>
  </defs>
</svg>
