import { Observer } from 'mobx-react-lite';
import React from 'react';
import videoPoster from '../../content/images/gcn/gcn-responsive-animation-poster.png';
// import './GCNResponsiveDesignVideo.scss';
import videoMp4 from '../../content/images/gcn/gcn-responsive-animation.mp4';
import videoWebm from '../../content/images/gcn/gcn-responsive-animation.webmsd.webm';
import NativeVideoWrapper from '../NativeVideoWrapper/NativeVideoWrapper';

type GCNResponsiveDesignVideoProps = {
  rounded?: boolean,
}

const GCNResponsiveDesignVideo: React.FC<GCNResponsiveDesignVideoProps> = props => {
  return <Observer children={() => (
    <NativeVideoWrapper 
      id="GCNResponsiveDesignVideo"
      className="GCNResponsiveDesignVideo" 
      src={[videoWebm, videoMp4]}
      poster={videoPoster}
      width={1280}
      height={720}
      onlyLoadWhenVisible
      rounded={props.rounded}
    />
  )} />
}

export default GCNResponsiveDesignVideo;