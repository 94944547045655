import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Hashtag } from '../../constants/hashtags.constants';
import { useEscapeToMarlboroughInfo } from '../../content/projects/escape-to-marlborough.project';
import { useGymCoachInfo } from '../../content/projects/gymcoach.project';
import { useMJHudsonBridgeInfo } from '../../content/projects/mjhudson-bridge.project';
import { useTurn2meInfo } from '../../content/projects/turn2me.project';
import ElyxrPageAnimationVideo from '../ElyxrPageAnimationVideo/ElyxrPageAnimationVideo';
import GatsbyImageAdaptor from '../GatsbyImageAdaptor/GatsbyImageAdaptor';
import GCNResponsiveDesignVideo from '../GCNResponsiveDesignVideo/GCNResponsiveDesignVideo';
import GridRow from '../GridRow/GridRow';
import './DesignGalleryDigital.scss';

type DesignGalleryDigitalProps = {}

// const DesignGalleryDigitalItem = (props: React.PropsWithChildren<{
//   image: ImageWithMetaInfo,
//   id: string
// }>) => {
//   const id = `DesignGallery--design--${props.id}`;
//   const ref = useObservableRef();
//   useFloatInOnVisible(id, ref);
//   return <div id={id} ref={ref} className="DesignGalleryDigitalItem">
//     <GatsbyImageAdaptor image={props.image} />
//   </div>
// };

const DesignGalleryDigital: React.FC<DesignGalleryDigitalProps> = props => {
  const imageMetaArray = [
    useTurn2meInfo().gallery?.[1],
    // <GCNGIFWrapper key="GCNGIFWrapper"/>,
    <GCNResponsiveDesignVideo key="GCNResponsiveDesignVideo" />,
    useEscapeToMarlboroughInfo().gallery?.[1],
    useGymCoachInfo().gallery?.[0],
    useMJHudsonBridgeInfo().gallery?.[0],
    // <ElyxrGIFWrapper key="ElyxrGIFWrapper"/>
    <ElyxrPageAnimationVideo key="ElyxrPageAnimationVideo" />,
  ]
  return <Observer children={() => (
    <div className="DesignGallery DesignGalleryDigital" id={Hashtag.digitalDesign}>
      <header className="DesignGalleryHeader">
        <h2>#Digital</h2>
      </header>
      <GridRow>
        {imageMetaArray.map((imageMeta, i) => {
          if (imageMeta && 'image' in imageMeta) {
            return <GatsbyImageAdaptor image={imageMeta} key={i}/>
          } else {
            return imageMeta
          }
        })}
      </GridRow>
    </div>
  )} />
}

export default DesignGalleryDigital;