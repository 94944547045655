import React from 'react';

export const TougherOilLogoDefault = <svg width="410" height="95" viewBox="0 0 410 95" fill="none" xmlns="http://www.w3.org/2000/svg" style={{aspectRatio: '410 / 95'}}>
  <path d="M244.309 37.5381C234.775 37.5381 225.813 44.3653 225.813 56.8888V57.123C225.813 67.146 232.254 76.1577 244.478 76.1577C252.178 76.1577 257.221 74.8723 259.748 74.0224V66.3383H245.04C241.022 66.3383 238.718 63.6856 238.154 60.8971H257.238C259.055 60.8971 260.937 60.066 261.635 58.79C262.234 57.6919 262.447 55.7299 262.447 53.2294C262.447 44.6018 254.442 37.5381 244.309 37.5381ZM250.736 52.8595H238.025C238.212 49.5395 241.214 46.9313 244.529 46.9313C248.772 46.9313 250.965 49.3405 250.736 52.8595Z" fill="black" />
  <path d="M355.498 39.1723V75.6965H367.204V38.1398H356.53C356.256 38.1398 355.994 38.2486 355.8 38.4423C355.606 38.6359 355.498 38.8985 355.498 39.1723Z" fill="black" />
  <path d="M0 38.594V48.8957H13.6966V75.6754H25.403V48.8957H38.1631V37.1892H1.40477C1.03221 37.1892 0.674895 37.3373 0.411449 37.6007C0.148003 37.8641 0 38.2215 0 38.594Z" fill="black" />
  <path d="M114.194 39.5305V59.6351C114.194 61.3279 113.911 62.3159 111.481 63.2314C109.743 63.8869 109.76 63.9337 108.865 64.1983C108.132 64.4317 107.37 64.5602 106.601 64.5799C104.567 64.5799 103.192 63.3344 103.192 59.682V38.1257H92.8907C92.5181 38.1257 92.1608 38.2737 91.8974 38.5371C91.6339 38.8006 91.4859 39.1579 91.4859 39.5305V63.524C91.4859 71.2971 96.1123 76.2981 102.443 76.2981C104.2 76.2781 105.938 75.9345 107.571 75.2843C110.614 74.1137 110.614 74.1137 112.89 73.1982C113.073 73.1174 113.27 73.0722 113.471 73.0648C114.112 73.0648 114.194 73.7297 114.194 74.1558V75.6753H125.901V38.1257H115.599C115.226 38.1257 114.869 38.2737 114.606 38.5371C114.342 38.8006 114.194 39.1579 114.194 39.5305Z" fill="black" />
  <path d="M173.429 34.8853H163.127C162.744 34.8958 162.381 35.0577 162.118 35.3356C161.855 35.6135 161.712 35.9846 161.722 36.3674V39.1559C158.763 37.8316 155.52 37.2688 152.288 37.5185C149.055 37.7683 145.937 38.8227 143.216 40.5858C140.496 42.3489 138.26 44.7646 136.711 47.6129C135.163 50.4612 134.352 53.6516 134.352 56.8935C134.352 60.1354 135.163 63.3258 136.711 66.1741C138.26 69.0224 140.496 71.4381 143.216 73.2012C145.937 74.9643 149.055 76.0187 152.288 76.2684C155.52 76.5182 158.763 75.9553 161.722 74.6311V75.6074L161.671 75.6238C161.496 77.5314 160.614 79.3048 159.199 80.596C157.784 81.8872 155.938 82.603 154.022 82.6032V82.6477H140.63V93.6518C140.63 93.6518 147.026 94.3541 154.022 94.3541C159.053 94.3526 163.888 92.3998 167.508 88.9065C171.129 85.4132 173.254 80.6517 173.436 75.6238L173.429 34.8853ZM153.79 64.5753C152.271 64.5753 150.786 64.1247 149.522 63.2806C148.259 62.4366 147.274 61.2368 146.693 59.8332C146.112 58.4295 145.96 56.885 146.256 55.3948C146.552 53.9047 147.284 52.536 148.358 51.4616C149.433 50.3873 150.801 49.6557 152.291 49.3593C153.782 49.0629 155.326 49.215 156.73 49.7964C158.133 50.3779 159.333 51.3624 160.177 52.6257C161.021 53.889 161.472 55.3742 161.472 56.8935C161.472 57.9025 161.273 58.9016 160.887 59.8337C160.501 60.7659 159.935 61.6128 159.221 62.3261C158.508 63.0395 157.66 63.6053 156.728 63.9912C155.796 64.3771 154.797 64.5756 153.788 64.5753H153.79Z" fill="black" />
  <path d="M204.666 37.9665C203.197 37.9456 201.743 38.2611 200.415 38.889C200.415 38.889 198.591 39.6382 196.924 40.3687C196.776 40.4337 196.617 40.468 196.455 40.4694C196.293 40.4688 196.132 40.4362 195.983 40.3735C195.833 40.3108 195.697 40.2192 195.583 40.104C195.468 39.9888 195.378 39.8521 195.316 39.7019C195.255 39.5517 195.223 39.3908 195.224 39.2285L195.205 39.2566V21.0718H184.903C184.531 21.0718 184.174 21.2198 183.91 21.4832C183.647 21.7467 183.499 22.104 183.499 22.4766V75.7035H195.205V53.2552L195.224 53.2973C195.224 51.2838 197.472 49.6098 200.124 49.296C200.372 49.2675 200.622 49.2534 200.871 49.2539C202.861 49.2539 206.022 50.1459 206.214 55.0111V75.6754H217.92V51.2112C217.93 42.7966 210.772 37.9665 204.666 37.9665Z" fill="black" />
  <path d="M297.645 36.1824L283.237 42.8247V38.1421H272.563C272.289 38.1421 272.027 38.2509 271.833 38.4445C271.639 38.6382 271.531 38.9008 271.531 39.1746V75.6988H283.237V51.6069L299.216 47.2942V37.1845C299.216 36.9995 299.17 36.8175 299.081 36.6551C298.992 36.4928 298.864 36.3554 298.709 36.2556C298.553 36.1558 298.374 36.0968 298.19 36.084C298.005 36.0712 297.82 36.1051 297.652 36.1824H297.645Z" fill="black" />
  <path d="M390.851 64.1282V38.1398H380.178C379.904 38.1398 379.641 38.2486 379.447 38.4423C379.254 38.6359 379.145 38.8985 379.145 39.1723V75.7105H409.961L410.001 64.1282H390.851Z" fill="black" />
  <path d="M63.6486 37.4608C59.8052 37.4608 56.0481 38.6006 52.8524 40.7358C49.6567 42.8711 47.166 45.9061 45.6951 49.457C44.2243 53.0078 43.8395 56.9151 44.5893 60.6847C45.3391 64.4543 47.1899 67.9169 49.9076 70.6346C52.6253 73.3523 56.0879 75.2031 59.8575 75.9529C63.6271 76.7027 67.5344 76.3179 71.0852 74.8471C74.6361 73.3762 77.6711 70.8855 79.8064 67.6898C81.9417 64.4941 83.0814 60.737 83.0814 56.8936C83.0814 54.3416 82.5787 51.8147 81.6021 49.457C80.6256 47.0993 79.1941 44.9571 77.3897 43.1526C75.5852 41.3481 73.4429 39.9167 71.0852 38.9401C68.7275 37.9635 66.2006 37.4608 63.6486 37.4608ZM63.6486 64.5753C62.1294 64.5749 60.6445 64.124 59.3815 63.2796C58.1186 62.4352 57.1343 61.2353 56.5533 59.8316C55.9722 58.4279 55.8204 56.8835 56.1171 55.3935C56.4138 53.9035 57.1456 52.535 58.22 51.4609C59.2944 50.3868 60.6631 49.6554 62.1532 49.3592C63.6433 49.063 65.1877 49.2153 66.5912 49.7968C67.9947 50.3782 69.1943 51.3628 70.0383 52.626C70.8823 53.8893 71.3328 55.3744 71.3328 56.8936C71.3328 57.9025 71.134 58.9016 70.7478 59.8338C70.3616 60.7659 69.7956 61.6129 69.082 62.3262C68.3684 63.0396 67.5213 63.6054 66.589 63.9913C65.6568 64.3772 64.6576 64.5757 63.6486 64.5753Z" fill="black" />
  <path d="M357.418 38.6314C360.674 44.0949 362.387 50.339 362.376 56.6991C362.386 63.4196 360.494 70.0057 356.919 75.6964H355.498V39.1722C355.498 38.8984 355.606 38.6358 355.8 38.4421C355.994 38.2485 356.256 38.1397 356.53 38.1397C356.708 38.1381 356.883 38.1826 357.039 38.2687C357.194 38.3549 357.325 38.4798 357.418 38.6314Z" fill="#EE4633" />
  <path d="M299.217 47.2941L290.702 49.5979C291.611 45.0939 293.383 40.8085 295.921 36.9783L297.653 36.18C297.821 36.1026 298.006 36.0687 298.191 36.0815C298.375 36.0943 298.554 36.1533 298.71 36.2531C298.865 36.3529 298.994 36.4903 299.082 36.6526C299.171 36.815 299.217 36.997 299.217 37.182V47.2941Z" fill="#EE4633" />
  <path d="M340.928 70.7844C332.499 79.213 319.304 79.213 310.875 70.7844C304.273 64.1819 304.008 53.2996 307.691 46.2383C311.282 39.3525 314.843 36.1216 317.33 31.9564C321.116 25.6162 323.242 21.2637 323.242 11.8868C324.766 13.5562 327.334 16.8504 328.411 19.3134C328.411 27.8802 324.822 33.5953 321.387 36.639C326.929 35.4683 332.157 28.4445 332.157 22.9799C336.372 26.8032 336.215 33.5157 342.827 44.4847C349.246 55.1399 346.243 65.472 340.928 70.7844ZM325.796 64.1187C326.771 64.1178 327.736 63.9249 328.636 63.5511C329.536 63.1772 330.354 62.6298 331.042 61.9399C331.731 61.2501 332.277 60.4314 332.649 59.5306C333.021 58.6298 333.212 57.6645 333.211 56.6898C333.211 55.2219 332.776 53.7869 331.96 52.5664C331.145 51.3459 329.985 50.3946 328.629 49.8329C327.273 49.2711 325.781 49.1241 324.341 49.4105C322.901 49.6969 321.579 50.4038 320.541 51.4417C319.503 52.4797 318.796 53.8022 318.51 55.2419C318.223 56.6816 318.37 58.1739 318.932 59.53C319.494 60.8862 320.445 62.0454 321.666 62.8609C322.886 63.6764 324.321 64.1117 325.789 64.1117L325.796 64.1187Z" fill="#EE4633" />
  <path d="M338.206 27.7045C334.537 14.3591 323.924 15.3753 323.924 0C328.138 11.8821 338.206 11.3038 338.206 27.7045Z" fill="#EE4633" />
</svg>
