import { Observer } from 'mobx-react-lite';
import React from 'react';
import videoPosterPng from '../../content/images/elyxr/elyxr-page-animation-sample-poster.png';
import videoPosterWebp from '../../content/images/elyxr/elyxr-page-animation-sample-poster.webp';
// import './ElyxrPageAnimationVideo.scss';
import videoMp4 from '../../content/images/elyxr/elyxr-page-animation-sample.mp4';
import videoWebm from '../../content/images/elyxr/elyxr-page-animation-sample.webmsd.webm';
import NativeVideoWrapper from '../NativeVideoWrapper/NativeVideoWrapper';

type ElyxrPageAnimationVideoProps = {}

const ElyxrPageAnimationVideo: React.FC<ElyxrPageAnimationVideoProps> = props => {
  return <Observer children={() => (
    <NativeVideoWrapper 
      id="ElyxrPageAnimationVideo"
      className="ElyxrPageAnimationVideo"
      src={[videoWebm, videoMp4]}
      posterWebp={videoPosterWebp}
      poster={videoPosterPng}
      width={1312}
      height={720}
      onlyLoadWhenVisible
    />
  )} />
}

export default ElyxrPageAnimationVideo;