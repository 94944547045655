import React from 'react';

export const EscapeToMarlboroughLogoDefault = <svg width="365" height="72" viewBox="0 0 365 72" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ aspectRatio: '365 / 72' }}>
  <path d="M285.772 23.7362C285.772 23.7362 299.375 19.7956 315.363 31.0147C331.351 42.2338 337.942 31.6637 350.33 31.0147C362.671 30.3657 364.915 36.717 364.915 36.717C364.915 36.717 357.997 49.9759 330.883 50.5322C303.723 51.0885 283.107 25.0807 285.772 23.7362Z" fill="url(#OCO2BF0_linear)" />
  <path d="M282.875 22.3918C282.875 22.3918 282.033 38.9423 286.989 50.0223C291.944 61.1023 302.789 71.8577 321.722 71.8577C341.028 73.4803 363.374 61.195 364.963 36.6243C364.122 36.4388 362.485 39.5449 356.315 40.843C326.069 48.446 329.575 25.9615 310.83 29.5776C291.056 33.6109 282.922 19.6566 282.875 22.3918Z" fill="url(#OCO2BF1_linear)" />
  <path d="M342.945 19.6566C348.418 19.6566 352.855 15.2563 352.855 9.82828C352.855 4.40027 348.418 0 342.945 0C337.471 0 333.034 4.40027 333.034 9.82828C333.034 15.2563 337.471 19.6566 342.945 19.6566Z" fill="url(#OCO2BF2_linear)" />
  <path d="M6.02991 23.9218C9.25547 23.9218 11.7798 26.1934 11.8266 30.4121H2.71085V30.5048C2.71085 33.101 3.83279 35.4653 6.45063 35.4653C7.99329 35.4653 9.72294 34.4918 10.2372 32.5447L11.6396 32.9619C11.0319 35.5117 9.06848 37.227 6.2169 37.227C2.19663 37.227 -0.000488281 34.0282 -0.000488281 30.7366C-0.000488281 27.2133 2.14989 23.9218 6.02991 23.9218ZM9.25547 29.1604C9.16197 26.8424 7.8063 25.3589 6.1234 25.3589C4.39375 25.3589 2.99134 26.8888 2.80435 29.1604H9.25547Z" fill="black" />
  <path d="M13.696 34.0282C13.696 32.9619 14.4907 32.2665 15.3322 32.2665C16.1269 32.2665 16.9216 32.6838 16.9216 33.7037C16.9216 34.5382 16.3606 35.0945 15.7529 35.0945C16.3606 35.5117 17.4825 35.8826 18.5577 35.8826C20.2406 35.8826 21.9235 35.419 21.9235 33.7964C21.9235 30.5976 13.7895 31.9884 13.7895 27.5378C13.7895 24.7562 16.9683 23.9681 18.7915 23.9681C20.9886 23.9681 23.8402 24.9417 23.8402 26.8424C23.8402 27.9551 23.0922 28.6041 22.2975 28.6041C21.4561 28.6041 20.6614 28.0942 20.6614 27.0742C20.6614 26.3325 21.1756 26.008 21.5495 25.7762C20.8951 25.4516 19.8666 25.2198 18.885 25.2198C17.3423 25.2198 15.9866 25.8689 15.9866 27.2597C15.9866 29.8558 24.1674 28.7432 24.1674 33.4719C24.1674 36.4389 21.4093 37.2734 18.8382 37.2734C16.5476 37.227 13.696 36.3462 13.696 34.0282Z" fill="black" />
  <path d="M32.4421 23.9218C34.6859 23.9218 37.4908 25.2198 37.4908 28.0478C37.4908 28.8823 36.8831 29.9485 35.6209 29.9485C34.6392 29.9485 33.7977 29.3458 33.7977 28.2796C33.7977 27.1669 34.7327 26.657 35.6209 26.657C35.1534 25.8689 34.1717 25.2198 32.8628 25.2198C30.0112 25.2198 28.7023 27.9087 28.7023 30.4121C28.7023 33.4255 30.1982 35.2799 32.6758 35.2799C34.7327 35.2799 36.0884 33.4255 36.3688 32.4983L37.7245 32.9156C37.1635 35.419 35.1534 37.1807 32.1148 37.1807C28.7023 37.1807 25.9442 34.7236 25.9442 30.783C25.9909 27.2597 28.1881 23.9218 32.4421 23.9218Z" fill="black" />
  <path d="M50.2064 37.227C48.6637 37.227 47.121 36.2998 46.9341 34.3063C46.2796 36.0217 44.4564 37.227 42.5866 37.227C40.7167 37.227 39.3142 36.1607 39.3142 34.3063C39.3142 30.3658 46.7938 30.8294 46.7938 27.816C46.7938 25.9616 45.8121 25.1735 44.2695 25.1735C43.1943 25.1735 42.0723 25.5443 41.5114 26.147C42.5866 26.147 43.1475 26.7961 43.1475 27.4915C43.1475 28.5114 42.3528 29.1604 41.5114 29.1604C40.6699 29.1604 39.7817 28.6041 39.7817 27.2597C39.7817 26.0543 41.3711 23.9218 44.8304 23.9218C47.308 23.9218 49.4117 24.988 49.4117 28.1405V33.5646C49.4117 34.3527 49.7389 35.5581 50.8141 35.5581C51.936 35.5581 52.31 34.7236 52.31 33.4719V31.7102H53.6189V33.2401C53.5254 36.4389 52.0763 37.227 50.2064 37.227ZM43.241 35.5117C44.8304 35.5117 46.7938 33.8427 46.7938 32.5447V30.0412C44.4097 32.3592 41.8386 32.1738 41.8386 34.2136C41.8386 34.9554 42.2593 35.5117 43.241 35.5117Z" fill="black" />
  <path d="M61.2378 23.9218C64.2764 23.9218 66.3333 26.8424 66.3333 30.5512C66.3333 34.3991 64.2764 37.227 60.9106 37.227C59.5082 37.227 58.1992 36.5316 57.4045 35.3263V40.194H60.0224V41.6312H52.0753V40.2404H54.8802V25.5907H52.5896V24.2926L57.3578 23.9681V26.3325C58.246 24.7562 59.6016 23.9218 61.2378 23.9218ZM60.4898 25.4053C58.5732 25.4053 57.2643 27.5842 57.2643 30.5976C57.2643 33.0083 58.1525 35.8362 60.4898 35.8362C62.7805 35.8362 63.6219 32.9619 63.6219 30.3658C63.6219 27.9087 62.5467 25.4053 60.4898 25.4053Z" fill="black" />
  <path d="M74.1411 23.9218C77.3666 23.9218 79.891 26.1934 79.9377 30.4121H70.7752V30.5048C70.7752 33.101 71.8972 35.4653 74.515 35.4653C76.0577 35.4653 77.7873 34.4918 78.3016 32.5447L79.704 32.9619C79.0963 35.5117 77.1329 37.227 74.2813 37.227C70.261 37.227 68.0639 34.0282 68.0639 30.7366C68.1106 27.2133 70.261 23.9218 74.1411 23.9218ZM77.3666 29.1604C77.2731 26.8424 75.9174 25.3589 74.2345 25.3589C72.5049 25.3589 71.1025 26.8888 70.9155 29.1604H77.3666Z" fill="black" />
  <path d="M90.6423 19.193C90.8293 19.193 90.9695 19.3321 90.9695 19.6102V24.2926H95.6443V25.637H90.9695V33.5182C90.9695 34.9553 91.7175 35.6044 92.6992 35.6044C93.8679 35.6044 94.6158 35.0017 94.6158 32.8228V31.0611H96.0182V32.4983C96.0182 35.3726 95.1768 37.1806 92.2317 37.1806C90.3151 37.1806 88.3984 36.0216 88.3984 33.3791V25.5907H86.0143V25.0343C89.2399 24.0608 89.8943 19.193 90.6423 19.193Z" fill="black" />
  <path d="M97.7012 30.5976C97.7012 26.8424 99.9919 23.9681 103.919 23.9681C107.799 23.9681 110.089 26.8424 110.089 30.5976C110.089 34.3064 107.799 37.227 103.919 37.227C100.039 37.227 97.7012 34.3527 97.7012 30.5976ZM100.413 30.7367C100.413 33.7037 101.722 35.7435 103.919 35.7435C106.069 35.7435 107.331 33.7037 107.331 30.7367C107.331 27.6769 106.303 25.3126 103.872 25.3126C101.441 25.3126 100.413 27.6769 100.413 30.7367Z" fill="black" />
  <path d="M116.54 36.9025V34.8626H118.831V26.1006H116.54V24.3853L121.822 24.1072V26.4252C122.57 24.7099 124.3 23.8754 125.796 23.8754C126.778 23.8754 128.32 24.0145 128.975 26.4252C129.676 24.5244 131.546 23.8754 133.089 23.8754C134.304 23.8754 136.782 24.2926 136.782 28.3259V34.8163H138.698V36.8561H132.154V34.8163H133.743V27.955C133.743 26.5179 132.948 25.9152 132.06 25.9152C130.564 25.9152 129.442 27.5842 129.442 28.8359V34.8626H131.078V36.9025H124.721V34.8626H126.404V27.955C126.404 26.657 125.702 25.8688 124.861 25.8688C122.804 25.8688 121.869 28.7432 121.869 31.0148V34.8626H123.505V36.9025H116.54Z" fill="black" />
  <path d="M150.947 37.227C149.451 37.1806 148.049 36.6707 147.581 34.909C146.74 36.2998 145.15 37.227 143.234 37.227C141.224 37.227 139.868 36.1607 139.868 34.1209C139.868 30.1803 147.394 30.9221 147.394 27.9087C147.394 26.0079 146.319 25.4052 144.917 25.4052C144.262 25.4052 143.608 25.5443 143.047 25.7761C143.561 25.8688 144.122 26.7033 144.122 27.3987C144.122 27.4451 144.122 27.4451 144.122 27.4914C144.122 28.4186 143.374 29.1604 142.159 29.1604C140.896 29.1604 140.195 28.2796 140.195 27.1669V27.1206C140.242 25.1271 142.72 23.8754 145.104 23.8754C147.628 23.8754 150.339 24.8026 150.339 28.1405V33.1473C150.339 33.9355 150.433 35.2335 151.508 35.2335C152.537 35.2335 152.63 34.1209 152.63 33.1937V31.6175H154.219V33.2401C154.219 36.4389 152.63 37.1806 150.9 37.1806C150.994 37.227 150.994 37.227 150.947 37.227ZM147.348 32.4983V30.3657C144.543 32.591 142.766 32.4056 142.766 34.0745C142.766 34.8163 143.047 35.419 143.842 35.419C145.057 35.3726 147.348 33.9355 147.348 32.4983Z" fill="black" />
  <path d="M162.867 36.9024H155.107V34.8626H157.257V26.1006H154.966V24.3853L160.202 24.1071V26.4251C160.763 24.988 162.072 24.1535 163.661 24.1071C164.83 24.0608 166.139 24.5707 166.139 26.286C166.139 27.5841 165.111 28.3722 164.222 28.3722C163.381 28.3722 162.446 27.8623 162.446 26.8424C162.446 26.1933 162.539 26.0079 162.68 25.9152C160.623 26.1006 160.296 28.5577 160.296 30.6902V34.8626H162.867V36.9024Z" fill="black" />
  <path d="M172.123 19.0076V34.8626H174.46V36.9024H166.653V34.8626H169.131V20.9547H166.747V19.0076H172.123Z" fill="black" />
  <path d="M178.762 35.0481L177.827 36.9025H176.237V20.9547H173.713V19.0076H179.276V25.8225C180.07 24.6635 181.239 24.0608 182.642 23.9217H182.969C186.054 23.9217 188.158 26.9351 188.158 30.5975C188.158 34.3527 186.054 37.227 182.875 37.227C181.333 37.227 179.743 36.4852 178.762 35.0481ZM179.276 30.0412C179.276 33.24 180.164 35.0944 182.127 35.0944C184.184 35.0944 184.932 32.7764 184.932 30.7366C184.932 28.6504 184.465 25.8688 182.174 25.8688C179.977 25.8688 179.276 28.0941 179.276 30.0412Z" fill="black" />
  <path d="M196.151 37.227C192.271 37.227 189.747 34.3527 189.747 30.5976C189.747 26.8424 192.224 23.9218 196.104 23.9218C199.984 23.9218 202.462 26.7961 202.462 30.5976C202.509 34.3063 200.031 37.227 196.151 37.227ZM196.151 25.8689C193.486 25.8689 192.972 28.6041 192.972 30.7366C192.972 32.7765 193.72 35.0945 196.151 35.0945C198.535 35.0945 199.283 32.7301 199.283 30.7366C199.283 28.6505 198.816 25.8689 196.151 25.8689Z" fill="black" />
  <path d="M203.911 36.9024V34.8626H206.062V26.1006H203.818V24.3853L209.054 24.1071V26.4251C209.615 24.988 210.923 24.1535 212.513 24.1071C213.682 24.0608 214.99 24.5707 214.99 26.286C214.99 27.5841 213.962 28.3722 213.074 28.3722C212.232 28.3722 211.297 27.8623 211.297 26.8424C211.297 26.1933 211.391 26.0079 211.531 25.9152C209.474 26.1006 209.147 28.5577 209.147 30.6902V34.8626H211.718V36.9024H203.911Z" fill="black" />
  <path d="M222.003 37.227C218.123 37.227 215.598 34.3527 215.598 30.5976C215.598 26.8424 218.076 23.9218 221.956 23.9218C225.836 23.9218 228.314 26.7961 228.314 30.5976C228.36 34.3063 225.883 37.227 222.003 37.227ZM222.003 25.8689C219.338 25.8689 218.824 28.6041 218.824 30.7366C218.824 32.7765 219.572 35.0945 222.003 35.0945C224.387 35.0945 225.135 32.7301 225.135 30.7366C225.135 28.6505 224.667 25.8689 222.003 25.8689Z" fill="black" />
  <path d="M234.016 24.2462V32.8691C234.016 34.6308 235.138 35.1407 236.12 35.1407C238.177 35.1407 239.205 33.5181 239.205 31.2001V26.1006H237.008V24.2462H242.244V34.8626H244.207V36.7633L239.252 36.9951V34.6308C238.738 36.3461 236.821 37.2269 235.232 37.2269C233.923 37.2269 230.978 36.856 230.978 32.7764V26.1006H228.827V24.2462H234.016Z" fill="black" />
  <path d="M246.498 40.1477C246.498 40.1013 246.498 40.1013 246.498 40.055C246.498 39.0814 247.339 38.3396 248.461 38.3396C249.443 38.3396 250.144 39.035 250.144 39.9622C250.144 40.4258 249.957 40.9358 249.723 41.353C250.004 41.3994 250.378 41.4921 250.705 41.4921C253.276 41.4921 254.071 40.1477 254.071 36.9025V35.3726C253.276 36.5316 252.107 37.2734 250.705 37.2734C247.386 37.2734 245.189 34.3991 245.189 30.5976C245.189 26.8424 247.292 23.9218 250.471 23.9218C252.06 23.9218 253.556 24.7099 254.585 26.1007L255.567 24.2463H258.512V26.1007H257.109V36.4389C257.109 40.0086 255.707 43.0684 251.453 43.0684C249.115 43.1147 246.591 42.4193 246.498 40.1477ZM251.172 35.2799C253.369 35.2799 254.071 33.101 254.071 31.1539C254.071 27.9551 253.182 26.0543 251.219 26.0543C249.162 26.0543 248.414 28.4187 248.414 30.4585C248.414 32.5447 248.882 35.2799 251.172 35.2799Z" fill="black" />
  <path d="M275.388 34.8626V36.9025H267.861V34.8626H270.012V28.6504C270.012 27.0742 269.03 26.0543 267.861 26.0543C265.197 26.0543 264.542 28.3259 264.542 30.5975V34.909H266.693V36.9488H259.213V34.909H261.457V20.9547H258.979V19.0076H264.542V26.3324C265.524 24.3853 267.254 23.9217 268.749 23.9217C270.105 23.9217 273.003 24.3853 273.003 28.465V34.8626H275.388Z" fill="black" />
  <path d="M120.656 52.9429L119.534 48.8633L118.225 52.8966C117.991 53.592 117.804 53.8701 117.29 53.8701C116.869 53.8701 116.635 53.6383 116.495 52.9429L115.046 47.5188H113.971V46.8234H117.57V47.5188H116.542L117.617 52.2012L119.113 47.5188H120.282L121.684 52.2475L122.899 47.5188H121.824V46.8234H124.769V47.5188H123.881L122.432 52.8966C122.245 53.592 122.058 53.8238 121.591 53.8238C121.076 53.8238 120.843 53.592 120.656 52.9429Z" fill="black" />
  <path d="M130.986 53.7774C130.145 53.7774 129.35 53.2674 129.257 52.2011C128.883 53.1283 127.948 53.7774 126.919 53.7774C125.891 53.7774 125.143 53.221 125.143 52.2011C125.143 50.0686 129.163 50.3004 129.163 48.6778C129.163 47.7042 128.649 47.2406 127.808 47.2406C127.2 47.2406 126.592 47.4261 126.312 47.7506C126.873 47.7506 127.2 48.1215 127.2 48.4923C127.2 49.0487 126.779 49.3732 126.312 49.3732C125.844 49.3732 125.377 49.0487 125.377 48.3533C125.377 47.7042 126.218 46.5452 128.088 46.5452C129.444 46.5452 130.566 47.1016 130.566 48.8169V51.7375C130.566 52.1548 130.753 52.8038 131.314 52.8038C131.921 52.8038 132.108 52.3402 132.108 51.6912V50.764H132.809V51.5985C132.763 53.3601 131.968 53.7774 130.986 53.7774ZM127.2 52.8502C128.041 52.8502 129.116 51.9693 129.116 51.2276V49.8831C127.854 51.1349 126.452 51.0421 126.452 52.1548C126.452 52.5256 126.686 52.8502 127.2 52.8502Z" fill="black" />
  <path d="M134.727 44.0418C134.82 44.0418 134.914 44.1346 134.914 44.2736V46.7771H137.438V47.5188H134.914V51.7839C134.914 52.572 135.288 52.8966 135.849 52.8966C136.456 52.8966 136.877 52.572 136.877 51.4131V50.4859H137.625V51.274C137.625 52.8502 137.204 53.7774 135.568 53.7774C134.54 53.7774 133.511 53.1747 133.511 51.7376V47.5188H132.202V47.2407C133.932 46.6844 134.306 44.0418 134.727 44.0418Z" fill="black" />
  <path d="M141.738 46.5916C143.468 46.5916 144.824 47.797 144.87 50.0686H139.962V50.115C139.962 51.5057 140.57 52.7575 141.972 52.7575C142.814 52.7575 143.702 52.2475 144.029 51.1812L144.777 51.413C144.45 52.8038 143.421 53.6847 141.879 53.6847C139.728 53.6847 138.56 51.9694 138.56 50.2077C138.513 48.3533 139.635 46.5916 141.738 46.5916ZM143.468 49.4196C143.421 48.1678 142.673 47.3797 141.785 47.3797C140.85 47.3797 140.102 48.2142 140.009 49.4196H143.468Z" fill="black" />
  <path d="M145.431 53.5919V52.8965H146.693V47.5187H145.431V46.8234L147.862 46.7306L148.049 48.2141C148.236 47.5651 148.797 46.6379 149.872 46.6379C150.527 46.6379 151.134 47.0088 151.134 47.7505C151.134 48.4459 150.62 48.7705 150.153 48.7705C149.685 48.7705 149.264 48.4923 149.264 47.936C149.264 47.6578 149.545 47.3797 149.545 47.3797C148.47 47.3797 148.096 48.8168 148.096 49.9758V52.8965H149.545V53.5919H145.431Z" fill="black" />
  <path d="M109.202 53.6846C109.202 53.6383 109.202 53.5455 109.155 53.4992C109.015 53.5919 108.828 53.6383 108.641 53.6383C108.174 53.6383 107.8 53.221 107.8 52.8038C107.8 52.3402 108.314 51.8766 108.781 51.8766C109.296 51.8766 109.997 52.2938 109.997 53.221C109.997 53.8237 109.669 54.5655 108.828 55.1218C108.781 55.1218 108.781 55.1681 108.735 55.1681L108.407 54.6582C108.735 54.4727 109.202 54.0555 109.202 53.6846Z" fill="black" />
  <path d="M82.6963 52.9429L81.5744 48.8633L80.2654 52.8966C80.0317 53.592 79.8447 53.8701 79.3305 53.8701C78.9098 53.8701 78.676 53.6383 78.5358 52.9429L77.0866 47.5188H76.0114V46.8234H79.611V47.5188H78.5825L79.6577 52.2012L81.1536 47.5188H82.3223L83.7247 52.2475L84.9402 47.5188H83.865V46.8234H86.8101V47.5188H85.9219L84.4727 52.8966C84.2857 53.592 84.0987 53.8238 83.6312 53.8238C83.117 53.8238 82.8833 53.592 82.6963 52.9429Z" fill="black" />
  <path d="M90.971 52.8965V53.5919H87.091V52.8965H88.3064V47.5188H87.0442V46.8234H89.6621V52.8965H90.971ZM88.8674 43.7173C89.3816 43.7173 89.8023 44.1345 89.8023 44.6908C89.8023 45.2008 89.3816 45.618 88.8674 45.618C88.3532 45.618 87.9324 45.2008 87.9324 44.6908C87.9324 44.1345 88.3532 43.7173 88.8674 43.7173Z" fill="black" />
  <path d="M91.2981 52.8965H92.5603V47.5188H91.2981V46.8234L93.729 46.7307L93.8692 48.1214C94.3367 47.1015 95.3651 46.6843 96.1598 46.6843C96.9545 46.6843 98.2635 46.9625 98.2635 49.1414V52.9429H99.5724V53.6383H95.7391V52.9429H96.9078V49.1414C96.9078 47.9824 96.3936 47.5188 95.6924 47.5188C94.4302 47.5188 93.916 48.7241 93.916 49.9295V52.9429H95.0846V53.6383H91.2981V52.8965Z" fill="black" />
  <path d="M103.079 46.5916C104.809 46.5916 106.164 47.797 106.211 50.0686H101.303V50.115C101.303 51.5057 101.91 52.7575 103.313 52.7575C104.154 52.7575 105.042 52.2475 105.37 51.1812L106.118 51.413C105.79 52.8038 104.762 53.6847 103.219 53.6847C101.069 53.6847 99.9003 51.9694 99.9003 50.2077C99.8068 48.3533 100.975 46.5916 103.079 46.5916ZM104.809 49.4196C104.762 48.1678 104.014 47.3797 103.126 47.3797C102.191 47.3797 101.443 48.2142 101.349 49.4196H104.809Z" fill="black" />
  <path d="M160.951 52.6184C160.156 53.2674 159.408 53.7774 158.193 53.7774C156.556 53.7774 155.434 52.8502 155.434 51.4594C155.434 49.8368 156.229 49.5586 158.006 48.3533C157.538 47.5652 157.071 46.6843 157.071 46.0817C157.071 44.8299 158.099 43.9955 159.875 43.9955C161.044 43.9955 162.166 44.7372 162.166 45.7571C162.166 46.8698 161.512 47.5652 159.922 48.5851L161.371 50.6713L163.288 48.3996H162.213V47.3797H165.906V48.3996H164.737L161.979 51.6448C162.4 52.3402 162.867 52.7575 163.335 52.7575C163.849 52.7575 164.41 52.7111 164.41 51.274V50.3468H165.672V51.1349C165.672 52.7575 165.345 53.8701 163.428 53.8701C162.587 53.8237 161.886 53.6383 160.951 52.6184ZM160.249 51.6448L158.66 49.3268C157.538 50.0222 157.164 50.5786 157.164 51.1349C157.164 52.1084 157.819 52.4793 158.52 52.4793C159.127 52.4329 159.829 52.1084 160.249 51.6448ZM160.904 45.8962C160.904 45.2935 160.436 44.969 159.922 44.969C159.221 44.969 158.707 45.2472 158.707 45.9889C158.707 46.4989 159.127 47.1016 159.408 47.6115C160.203 47.0088 160.904 46.7307 160.904 45.8962Z" fill="black" />
  <path d="M176.285 52.9429L175.163 48.8633L173.854 52.8966C173.62 53.592 173.433 53.8701 172.919 53.8701C172.498 53.8701 172.264 53.6383 172.124 52.9429L170.675 47.5188H169.6V46.8234H173.199V47.5188H172.171L173.246 52.2012L174.742 47.5188H175.911L177.313 52.2475L178.529 47.5188H177.453V46.8234H180.399V47.5188H179.51L178.061 52.8966C177.874 53.592 177.687 53.8238 177.22 53.8238C176.705 53.8238 176.472 53.592 176.285 52.9429Z" fill="black" />
  <path d="M184.558 52.8965V53.5919H180.678V52.8965H181.894V47.5188H180.631V46.8234H183.249V52.8965H184.558ZM182.501 43.7173C183.016 43.7173 183.436 44.1345 183.436 44.6908C183.436 45.2008 183.016 45.618 182.501 45.618C181.987 45.618 181.566 45.2008 181.566 44.6908C181.52 44.1345 181.94 43.7173 182.501 43.7173Z" fill="black" />
  <path d="M187.598 52.8966H188.954V53.592H184.793V52.8966H186.195V44.83H184.793V44.0418H187.598V52.8966Z" fill="black" />
  <path d="M192.412 53.7774C190.729 53.7774 189.608 52.3402 189.608 50.2077C189.608 48.0751 190.729 46.638 192.506 46.638C193.207 46.638 193.955 47.0089 194.376 47.6579V44.8763H192.88V44.0418H195.778V52.8502H196.947V53.5456H194.656L194.423 52.4793C193.955 53.3602 193.114 53.7774 192.412 53.7774ZM191.103 50.2077C191.103 51.5521 191.618 52.9429 192.74 52.9429C193.768 52.9429 194.516 51.7376 194.516 50.115C194.516 48.8169 194.002 47.3334 192.786 47.3334C191.524 47.3334 191.103 48.8169 191.103 50.2077Z" fill="black" />
  <path d="M200.734 46.5916C202.463 46.5916 203.819 47.797 203.866 50.0686H198.957V50.115C198.957 51.5057 199.565 52.7575 200.967 52.7575C201.809 52.7575 202.697 52.2475 203.024 51.1812L203.772 51.413C203.445 52.8038 202.417 53.6847 200.874 53.6847C198.724 53.6847 197.555 51.9694 197.555 50.2077C197.461 48.3533 198.63 46.5916 200.734 46.5916ZM202.463 49.4196C202.417 48.1678 201.669 47.3797 200.781 47.3797C199.846 47.3797 199.098 48.2142 199.004 49.4196H202.463Z" fill="black" />
  <path d="M204.427 53.5919V52.8965H205.689V47.5187H204.427V46.8234L206.857 46.7306L207.044 48.2141C207.231 47.5651 207.792 46.6379 208.868 46.6379C209.522 46.6379 210.13 47.0088 210.13 47.7505C210.13 48.4459 209.615 48.7705 209.148 48.7705C208.681 48.7705 208.26 48.4923 208.26 47.936C208.26 47.6578 208.54 47.3797 208.54 47.3797C207.465 47.3797 207.091 48.8168 207.091 49.9758V52.8965H208.54V53.5919H204.427Z" fill="black" />
  <path d="M210.504 52.8965H211.766V47.5188H210.504V46.8234L212.935 46.7307L213.075 48.1214C213.543 47.1015 214.571 46.6843 215.366 46.6843C216.161 46.6843 217.47 46.9625 217.47 49.1414V52.9429H218.778V53.6383H214.945V52.9429H216.114V49.1414C216.114 47.9824 215.6 47.5188 214.898 47.5188C213.636 47.5188 213.122 48.7241 213.122 49.9295V52.9429H214.291V53.6383H210.504V52.8965Z" fill="black" />
  <path d="M222.331 46.5916C224.061 46.5916 225.417 47.797 225.463 50.0686H220.555V50.115C220.555 51.5057 221.163 52.7575 222.565 52.7575C223.406 52.7575 224.295 52.2475 224.622 51.1812L225.37 51.413C225.043 52.8038 224.014 53.6847 222.471 53.6847C220.321 53.6847 219.152 51.9694 219.152 50.2077C219.059 48.3533 220.181 46.5916 222.331 46.5916ZM224.061 49.4196C224.014 48.1678 223.266 47.3797 222.378 47.3797C221.443 47.3797 220.695 48.2142 220.602 49.4196H224.061Z" fill="black" />
  <path d="M226.443 52.062C226.443 51.5057 226.864 51.1348 227.331 51.1348C227.752 51.1348 228.173 51.3666 228.173 51.9229C228.173 52.3865 227.846 52.6647 227.565 52.6647C227.892 52.8965 228.5 53.0819 229.061 53.0819C229.949 53.0819 230.884 52.8038 230.884 51.9693C230.884 50.254 226.49 50.9957 226.49 48.585C226.49 47.1015 228.22 46.6379 229.154 46.6379C230.323 46.6379 231.866 47.1479 231.866 48.1678C231.866 48.7705 231.445 49.1413 231.024 49.1413C230.557 49.1413 230.136 48.8632 230.136 48.3069C230.136 47.8896 230.417 47.7505 230.604 47.6115C230.23 47.426 229.715 47.2869 229.154 47.2869C228.313 47.2869 227.612 47.6578 227.612 48.3996C227.612 49.7904 232.006 49.1877 232.006 51.7375C232.006 53.3137 230.51 53.7773 229.154 53.7773C227.939 53.7773 226.443 53.3137 226.443 52.062Z" fill="black" />
  <path d="M233.082 52.062C233.082 51.5057 233.503 51.1348 233.97 51.1348C234.391 51.1348 234.812 51.3666 234.812 51.9229C234.812 52.3865 234.484 52.6647 234.204 52.6647C234.531 52.8965 235.139 53.0819 235.7 53.0819C236.588 53.0819 237.523 52.8038 237.523 51.9693C237.523 50.254 233.129 50.9957 233.129 48.585C233.129 47.1015 234.858 46.6379 235.793 46.6379C236.962 46.6379 238.505 47.1479 238.505 48.1678C238.505 48.7705 238.084 49.1413 237.663 49.1413C237.196 49.1413 236.775 48.8632 236.775 48.3069C236.775 47.8896 237.056 47.7505 237.243 47.6115C236.869 47.426 236.354 47.2869 235.793 47.2869C234.952 47.2869 234.251 47.6578 234.251 48.3996C234.251 49.7904 238.645 49.1877 238.645 51.7375C238.645 53.3137 237.149 53.7773 235.793 53.7773C234.625 53.7773 233.082 53.3137 233.082 52.062Z" fill="black" />
  <path d="M244.675 44.0418C244.769 44.0418 244.862 44.1346 244.862 44.2736V46.7771H247.387V47.5188H244.862V51.7839C244.862 52.572 245.236 52.8966 245.797 52.8966C246.405 52.8966 246.826 52.572 246.826 51.4131V50.4859H247.574V51.274C247.574 52.8502 247.153 53.7774 245.517 53.7774C244.488 53.7774 243.46 53.1747 243.46 51.7376V47.5188H242.151V47.2407C243.927 46.6844 244.255 44.0418 244.675 44.0418Z" fill="black" />
  <path d="M248.462 50.1613C248.462 48.1215 249.724 46.5916 251.781 46.5916C253.884 46.5916 255.1 48.1678 255.1 50.1613C255.1 52.1548 253.884 53.731 251.781 53.731C249.724 53.7774 248.462 52.2011 248.462 50.1613ZM249.911 50.254C249.911 51.8766 250.612 52.9429 251.781 52.9429C252.949 52.9429 253.651 51.8303 253.651 50.254C253.651 48.5851 253.09 47.3334 251.781 47.3334C250.472 47.3334 249.911 48.6314 249.911 50.254Z" fill="black" />
  <path d="M261.083 52.3402C260.476 53.4529 259.587 53.7774 258.793 53.7774C257.998 53.7774 256.642 53.4992 256.642 51.3203V47.5188H255.427V46.8234H257.998V51.3203C257.998 52.4793 258.372 52.8966 259.12 52.8966C259.961 52.8966 261.037 52.0621 261.037 51.2276V47.5188H259.915V46.8234H262.439V52.8502L263.701 52.9893V53.592L261.13 53.7774V52.3402H261.083Z" fill="black" />
  <path d="M263.982 53.5919V52.8965H265.245V47.5187H263.982V46.8234L266.413 46.7306L266.6 48.2141C266.787 47.5651 267.348 46.6379 268.423 46.6379C269.078 46.6379 269.686 47.0088 269.686 47.7505C269.686 48.4459 269.171 48.7705 268.704 48.7705C268.236 48.7705 267.816 48.4923 267.816 47.936C267.816 47.6578 268.096 47.3797 268.096 47.3797C267.021 47.3797 266.647 48.8168 266.647 49.9758V52.8965H268.096V53.5919H263.982Z" fill="black" />
  <path d="M270.294 52.062C270.294 51.5057 270.714 51.1348 271.182 51.1348C271.603 51.1348 272.023 51.3666 272.023 51.9229C272.023 52.3865 271.696 52.6647 271.416 52.6647C271.743 52.8965 272.351 53.0819 272.911 53.0819C273.8 53.0819 274.735 52.8038 274.735 51.9693C274.735 50.254 270.34 50.9957 270.34 48.585C270.34 47.1015 272.07 46.6379 273.005 46.6379C274.174 46.6379 275.716 47.1479 275.716 48.1678C275.716 48.7705 275.296 49.1413 274.875 49.1413C274.407 49.1413 273.987 48.8632 273.987 48.3069C273.987 47.8896 274.267 47.7505 274.454 47.6115C274.08 47.426 273.566 47.2869 273.005 47.2869C272.164 47.2869 271.462 47.6578 271.462 48.3996C271.462 49.7904 275.857 49.1877 275.857 51.7375C275.857 53.3137 274.361 53.7773 273.005 53.7773C271.836 53.7773 270.294 53.3137 270.294 52.062Z" fill="black" />
  <defs>
    <linearGradient id="OCO2BF0_linear" x1="327.121" y1="22.9722" x2="322.655" y2="40.7036" gradientUnits="userSpaceOnUse">
      <stop stopColor="#99080F" />
      <stop offset="0.4067" stopColor="#7D0E14" />
      <stop offset="1" stopColor="#4D191D" />
    </linearGradient>
    <linearGradient id="OCO2BF1_linear" x1="297.828" y1="46.5618" x2="379.242" y2="57.3489" gradientUnits="userSpaceOnUse">
      <stop stopColor="#B51C19" />
      <stop offset="0.8133" stopColor="#52191D" />
    </linearGradient>
    <linearGradient id="OCO2BF2_linear" x1="333.064" y1="9.8216" x2="352.871" y2="9.8216" gradientUnits="userSpaceOnUse">
      <stop stopColor="#99080F" />
      <stop offset="1" stopColor="#63191D" />
    </linearGradient>
  </defs>
</svg>

export const EscapeToMarlboroughLogoReversed = <svg width="365" height="72" viewBox="0 0 365 72" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ aspectRatio: '365 / 72' }}>
  <path d="M285.772 23.7362C285.772 23.7362 299.375 19.7956 315.363 31.0147C331.351 42.2338 337.942 31.6637 350.33 31.0147C362.671 30.3657 364.915 36.717 364.915 36.717C364.915 36.717 357.997 49.9759 330.883 50.5322C303.723 51.0885 283.107 25.0807 285.772 23.7362Z" fill="url(#XV2BFO0_linear)" />
  <path d="M282.875 22.3918C282.875 22.3918 282.033 38.9423 286.989 50.0223C291.944 61.1023 302.789 71.8577 321.722 71.8577C341.028 73.4803 363.374 61.195 364.963 36.6243C364.122 36.4388 362.485 39.5449 356.315 40.843C326.069 48.446 329.575 25.9615 310.83 29.5776C291.056 33.6109 282.922 19.6566 282.875 22.3918Z" fill="url(#XV2BFO1_linear)" />
  <path d="M342.945 19.6566C348.418 19.6566 352.855 15.2563 352.855 9.82828C352.855 4.40027 348.418 0 342.945 0C337.471 0 333.034 4.40027 333.034 9.82828C333.034 15.2563 337.471 19.6566 342.945 19.6566Z" fill="url(#XV2BFO2_linear)" />
  <path d="M6.02991 23.9218C9.25547 23.9218 11.7798 26.1934 11.8266 30.4121H2.71085V30.5048C2.71085 33.101 3.83279 35.4653 6.45063 35.4653C7.99329 35.4653 9.72294 34.4918 10.2372 32.5447L11.6396 32.9619C11.0319 35.5117 9.06848 37.227 6.2169 37.227C2.19663 37.227 -0.000488281 34.0282 -0.000488281 30.7366C-0.000488281 27.2133 2.14989 23.9218 6.02991 23.9218ZM9.25547 29.1604C9.16197 26.8424 7.8063 25.3589 6.1234 25.3589C4.39375 25.3589 2.99134 26.8888 2.80435 29.1604H9.25547Z" fill="white" />
  <path d="M13.696 34.0282C13.696 32.9619 14.4907 32.2665 15.3322 32.2665C16.1269 32.2665 16.9216 32.6838 16.9216 33.7037C16.9216 34.5382 16.3606 35.0945 15.7529 35.0945C16.3606 35.5117 17.4825 35.8826 18.5577 35.8826C20.2406 35.8826 21.9235 35.419 21.9235 33.7964C21.9235 30.5976 13.7895 31.9884 13.7895 27.5378C13.7895 24.7562 16.9683 23.9681 18.7915 23.9681C20.9886 23.9681 23.8402 24.9417 23.8402 26.8424C23.8402 27.9551 23.0922 28.6041 22.2975 28.6041C21.4561 28.6041 20.6614 28.0942 20.6614 27.0742C20.6614 26.3325 21.1756 26.008 21.5495 25.7762C20.8951 25.4516 19.8666 25.2198 18.885 25.2198C17.3423 25.2198 15.9866 25.8689 15.9866 27.2597C15.9866 29.8558 24.1674 28.7432 24.1674 33.4719C24.1674 36.4389 21.4093 37.2734 18.8382 37.2734C16.5476 37.227 13.696 36.3462 13.696 34.0282Z" fill="white" />
  <path d="M32.4421 23.9218C34.6859 23.9218 37.4908 25.2198 37.4908 28.0478C37.4908 28.8823 36.8831 29.9485 35.6209 29.9485C34.6392 29.9485 33.7977 29.3458 33.7977 28.2796C33.7977 27.1669 34.7327 26.657 35.6209 26.657C35.1534 25.8689 34.1717 25.2198 32.8628 25.2198C30.0112 25.2198 28.7023 27.9087 28.7023 30.4121C28.7023 33.4255 30.1982 35.2799 32.6758 35.2799C34.7327 35.2799 36.0884 33.4255 36.3688 32.4983L37.7245 32.9156C37.1635 35.419 35.1534 37.1807 32.1148 37.1807C28.7023 37.1807 25.9442 34.7236 25.9442 30.783C25.9909 27.2597 28.1881 23.9218 32.4421 23.9218Z" fill="white" />
  <path d="M50.2064 37.227C48.6637 37.227 47.121 36.2998 46.9341 34.3063C46.2796 36.0217 44.4564 37.227 42.5866 37.227C40.7167 37.227 39.3142 36.1607 39.3142 34.3063C39.3142 30.3658 46.7938 30.8294 46.7938 27.816C46.7938 25.9616 45.8121 25.1735 44.2695 25.1735C43.1943 25.1735 42.0723 25.5443 41.5114 26.147C42.5866 26.147 43.1475 26.7961 43.1475 27.4915C43.1475 28.5114 42.3528 29.1604 41.5114 29.1604C40.6699 29.1604 39.7817 28.6041 39.7817 27.2597C39.7817 26.0543 41.3711 23.9218 44.8304 23.9218C47.308 23.9218 49.4117 24.988 49.4117 28.1405V33.5646C49.4117 34.3527 49.7389 35.5581 50.8141 35.5581C51.936 35.5581 52.31 34.7236 52.31 33.4719V31.7102H53.6189V33.2401C53.5254 36.4389 52.0763 37.227 50.2064 37.227ZM43.241 35.5117C44.8304 35.5117 46.7938 33.8427 46.7938 32.5447V30.0412C44.4097 32.3592 41.8386 32.1738 41.8386 34.2136C41.8386 34.9554 42.2593 35.5117 43.241 35.5117Z" fill="white" />
  <path d="M61.2378 23.9218C64.2764 23.9218 66.3333 26.8424 66.3333 30.5512C66.3333 34.3991 64.2764 37.227 60.9106 37.227C59.5082 37.227 58.1992 36.5316 57.4045 35.3263V40.194H60.0224V41.6312H52.0753V40.2404H54.8802V25.5907H52.5896V24.2926L57.3578 23.9681V26.3325C58.246 24.7562 59.6016 23.9218 61.2378 23.9218ZM60.4898 25.4053C58.5732 25.4053 57.2643 27.5842 57.2643 30.5976C57.2643 33.0083 58.1525 35.8362 60.4898 35.8362C62.7805 35.8362 63.6219 32.9619 63.6219 30.3658C63.6219 27.9087 62.5467 25.4053 60.4898 25.4053Z" fill="white" />
  <path d="M74.1411 23.9218C77.3666 23.9218 79.891 26.1934 79.9377 30.4121H70.7752V30.5048C70.7752 33.101 71.8972 35.4653 74.515 35.4653C76.0577 35.4653 77.7873 34.4918 78.3016 32.5447L79.704 32.9619C79.0963 35.5117 77.1329 37.227 74.2813 37.227C70.261 37.227 68.0639 34.0282 68.0639 30.7366C68.1106 27.2133 70.261 23.9218 74.1411 23.9218ZM77.3666 29.1604C77.2731 26.8424 75.9174 25.3589 74.2345 25.3589C72.5049 25.3589 71.1025 26.8888 70.9155 29.1604H77.3666Z" fill="white" />
  <path d="M90.6423 19.193C90.8293 19.193 90.9695 19.3321 90.9695 19.6102V24.2926H95.6443V25.637H90.9695V33.5182C90.9695 34.9553 91.7175 35.6044 92.6992 35.6044C93.8679 35.6044 94.6158 35.0017 94.6158 32.8228V31.0611H96.0182V32.4983C96.0182 35.3726 95.1768 37.1806 92.2317 37.1806C90.3151 37.1806 88.3984 36.0216 88.3984 33.3791V25.5907H86.0143V25.0343C89.2399 24.0608 89.8943 19.193 90.6423 19.193Z" fill="white" />
  <path d="M97.7012 30.5976C97.7012 26.8424 99.9919 23.9681 103.919 23.9681C107.799 23.9681 110.089 26.8424 110.089 30.5976C110.089 34.3064 107.799 37.227 103.919 37.227C100.039 37.227 97.7012 34.3527 97.7012 30.5976ZM100.413 30.7367C100.413 33.7037 101.722 35.7435 103.919 35.7435C106.069 35.7435 107.331 33.7037 107.331 30.7367C107.331 27.6769 106.303 25.3126 103.872 25.3126C101.441 25.3126 100.413 27.6769 100.413 30.7367Z" fill="white" />
  <path d="M116.54 36.9025V34.8626H118.831V26.1006H116.54V24.3853L121.822 24.1072V26.4252C122.57 24.7099 124.3 23.8754 125.796 23.8754C126.778 23.8754 128.32 24.0145 128.975 26.4252C129.676 24.5244 131.546 23.8754 133.089 23.8754C134.304 23.8754 136.782 24.2926 136.782 28.3259V34.8163H138.698V36.8561H132.154V34.8163H133.743V27.955C133.743 26.5179 132.948 25.9152 132.06 25.9152C130.564 25.9152 129.442 27.5842 129.442 28.8359V34.8626H131.078V36.9025H124.721V34.8626H126.404V27.955C126.404 26.657 125.702 25.8688 124.861 25.8688C122.804 25.8688 121.869 28.7432 121.869 31.0148V34.8626H123.505V36.9025H116.54Z" fill="white" />
  <path d="M150.947 37.227C149.451 37.1806 148.049 36.6707 147.581 34.909C146.74 36.2998 145.15 37.227 143.234 37.227C141.224 37.227 139.868 36.1607 139.868 34.1209C139.868 30.1803 147.394 30.9221 147.394 27.9087C147.394 26.0079 146.319 25.4052 144.917 25.4052C144.262 25.4052 143.608 25.5443 143.047 25.7761C143.561 25.8688 144.122 26.7033 144.122 27.3987C144.122 27.4451 144.122 27.4451 144.122 27.4914C144.122 28.4186 143.374 29.1604 142.159 29.1604C140.896 29.1604 140.195 28.2796 140.195 27.1669V27.1206C140.242 25.1271 142.72 23.8754 145.104 23.8754C147.628 23.8754 150.339 24.8026 150.339 28.1405V33.1473C150.339 33.9355 150.433 35.2335 151.508 35.2335C152.537 35.2335 152.63 34.1209 152.63 33.1937V31.6175H154.219V33.2401C154.219 36.4389 152.63 37.1806 150.9 37.1806C150.994 37.227 150.994 37.227 150.947 37.227ZM147.348 32.4983V30.3657C144.543 32.591 142.766 32.4056 142.766 34.0745C142.766 34.8163 143.047 35.419 143.842 35.419C145.057 35.3726 147.348 33.9355 147.348 32.4983Z" fill="white" />
  <path d="M162.867 36.9024H155.107V34.8626H157.257V26.1006H154.966V24.3853L160.202 24.1071V26.4251C160.763 24.988 162.072 24.1535 163.661 24.1071C164.83 24.0608 166.139 24.5707 166.139 26.286C166.139 27.5841 165.111 28.3722 164.222 28.3722C163.381 28.3722 162.446 27.8623 162.446 26.8424C162.446 26.1933 162.539 26.0079 162.68 25.9152C160.623 26.1006 160.296 28.5577 160.296 30.6902V34.8626H162.867V36.9024Z" fill="white" />
  <path d="M172.123 19.0076V34.8626H174.46V36.9024H166.653V34.8626H169.131V20.9547H166.747V19.0076H172.123Z" fill="white" />
  <path d="M178.762 35.0481L177.827 36.9025H176.237V20.9547H173.713V19.0076H179.276V25.8225C180.07 24.6635 181.239 24.0608 182.642 23.9217H182.969C186.054 23.9217 188.158 26.9351 188.158 30.5975C188.158 34.3527 186.054 37.227 182.875 37.227C181.333 37.227 179.743 36.4852 178.762 35.0481ZM179.276 30.0412C179.276 33.24 180.164 35.0944 182.127 35.0944C184.184 35.0944 184.932 32.7764 184.932 30.7366C184.932 28.6504 184.465 25.8688 182.174 25.8688C179.977 25.8688 179.276 28.0941 179.276 30.0412Z" fill="white" />
  <path d="M196.151 37.227C192.271 37.227 189.747 34.3527 189.747 30.5976C189.747 26.8424 192.224 23.9218 196.104 23.9218C199.984 23.9218 202.462 26.7961 202.462 30.5976C202.509 34.3063 200.031 37.227 196.151 37.227ZM196.151 25.8689C193.486 25.8689 192.972 28.6041 192.972 30.7366C192.972 32.7765 193.72 35.0945 196.151 35.0945C198.535 35.0945 199.283 32.7301 199.283 30.7366C199.283 28.6505 198.816 25.8689 196.151 25.8689Z" fill="white" />
  <path d="M203.911 36.9024V34.8626H206.062V26.1006H203.818V24.3853L209.054 24.1071V26.4251C209.615 24.988 210.923 24.1535 212.513 24.1071C213.682 24.0608 214.99 24.5707 214.99 26.286C214.99 27.5841 213.962 28.3722 213.074 28.3722C212.232 28.3722 211.297 27.8623 211.297 26.8424C211.297 26.1933 211.391 26.0079 211.531 25.9152C209.474 26.1006 209.147 28.5577 209.147 30.6902V34.8626H211.718V36.9024H203.911Z" fill="white" />
  <path d="M222.003 37.227C218.123 37.227 215.598 34.3527 215.598 30.5976C215.598 26.8424 218.076 23.9218 221.956 23.9218C225.836 23.9218 228.314 26.7961 228.314 30.5976C228.36 34.3063 225.883 37.227 222.003 37.227ZM222.003 25.8689C219.338 25.8689 218.824 28.6041 218.824 30.7366C218.824 32.7765 219.572 35.0945 222.003 35.0945C224.387 35.0945 225.135 32.7301 225.135 30.7366C225.135 28.6505 224.667 25.8689 222.003 25.8689Z" fill="white" />
  <path d="M234.016 24.2462V32.8691C234.016 34.6308 235.138 35.1407 236.12 35.1407C238.177 35.1407 239.205 33.5181 239.205 31.2001V26.1006H237.008V24.2462H242.244V34.8626H244.207V36.7633L239.252 36.9951V34.6308C238.738 36.3461 236.821 37.2269 235.232 37.2269C233.923 37.2269 230.978 36.856 230.978 32.7764V26.1006H228.827V24.2462H234.016Z" fill="white" />
  <path d="M246.498 40.1477C246.498 40.1013 246.498 40.1013 246.498 40.055C246.498 39.0814 247.339 38.3396 248.461 38.3396C249.443 38.3396 250.144 39.035 250.144 39.9622C250.144 40.4258 249.957 40.9358 249.723 41.353C250.004 41.3994 250.378 41.4921 250.705 41.4921C253.276 41.4921 254.071 40.1477 254.071 36.9025V35.3726C253.276 36.5316 252.107 37.2734 250.705 37.2734C247.386 37.2734 245.189 34.3991 245.189 30.5976C245.189 26.8424 247.292 23.9218 250.471 23.9218C252.06 23.9218 253.556 24.7099 254.585 26.1007L255.567 24.2463H258.512V26.1007H257.109V36.4389C257.109 40.0086 255.707 43.0684 251.453 43.0684C249.115 43.1147 246.591 42.4193 246.498 40.1477ZM251.172 35.2799C253.369 35.2799 254.071 33.101 254.071 31.1539C254.071 27.9551 253.182 26.0543 251.219 26.0543C249.162 26.0543 248.414 28.4187 248.414 30.4585C248.414 32.5447 248.882 35.2799 251.172 35.2799Z" fill="white" />
  <path d="M275.388 34.8626V36.9025H267.861V34.8626H270.012V28.6504C270.012 27.0742 269.03 26.0543 267.861 26.0543C265.197 26.0543 264.542 28.3259 264.542 30.5975V34.909H266.693V36.9488H259.213V34.909H261.457V20.9547H258.979V19.0076H264.542V26.3324C265.524 24.3853 267.254 23.9217 268.749 23.9217C270.105 23.9217 273.003 24.3853 273.003 28.465V34.8626H275.388Z" fill="white" />
  <path d="M120.656 52.9429L119.534 48.8633L118.225 52.8966C117.991 53.592 117.804 53.8701 117.29 53.8701C116.869 53.8701 116.635 53.6383 116.495 52.9429L115.046 47.5188H113.971V46.8234H117.57V47.5188H116.542L117.617 52.2012L119.113 47.5188H120.282L121.684 52.2475L122.899 47.5188H121.824V46.8234H124.769V47.5188H123.881L122.432 52.8966C122.245 53.592 122.058 53.8238 121.591 53.8238C121.076 53.8238 120.843 53.592 120.656 52.9429Z" fill="white" />
  <path d="M130.986 53.7774C130.145 53.7774 129.35 53.2674 129.257 52.2011C128.883 53.1283 127.948 53.7774 126.919 53.7774C125.891 53.7774 125.143 53.221 125.143 52.2011C125.143 50.0686 129.163 50.3004 129.163 48.6778C129.163 47.7042 128.649 47.2406 127.808 47.2406C127.2 47.2406 126.592 47.4261 126.312 47.7506C126.873 47.7506 127.2 48.1215 127.2 48.4923C127.2 49.0487 126.779 49.3732 126.312 49.3732C125.844 49.3732 125.377 49.0487 125.377 48.3533C125.377 47.7042 126.218 46.5452 128.088 46.5452C129.444 46.5452 130.566 47.1016 130.566 48.8169V51.7375C130.566 52.1548 130.753 52.8038 131.314 52.8038C131.921 52.8038 132.108 52.3402 132.108 51.6912V50.764H132.809V51.5985C132.763 53.3601 131.968 53.7774 130.986 53.7774ZM127.2 52.8502C128.041 52.8502 129.116 51.9693 129.116 51.2276V49.8831C127.854 51.1349 126.452 51.0421 126.452 52.1548C126.452 52.5256 126.686 52.8502 127.2 52.8502Z" fill="white" />
  <path d="M134.727 44.0418C134.82 44.0418 134.914 44.1346 134.914 44.2736V46.7771H137.438V47.5188H134.914V51.7839C134.914 52.572 135.288 52.8966 135.849 52.8966C136.456 52.8966 136.877 52.572 136.877 51.4131V50.4859H137.625V51.274C137.625 52.8502 137.204 53.7774 135.568 53.7774C134.54 53.7774 133.511 53.1747 133.511 51.7376V47.5188H132.202V47.2407C133.932 46.6844 134.306 44.0418 134.727 44.0418Z" fill="white" />
  <path d="M141.738 46.5916C143.468 46.5916 144.824 47.797 144.87 50.0686H139.962V50.115C139.962 51.5057 140.57 52.7575 141.972 52.7575C142.814 52.7575 143.702 52.2475 144.029 51.1812L144.777 51.413C144.45 52.8038 143.421 53.6847 141.879 53.6847C139.728 53.6847 138.56 51.9694 138.56 50.2077C138.513 48.3533 139.635 46.5916 141.738 46.5916ZM143.468 49.4196C143.421 48.1678 142.673 47.3797 141.785 47.3797C140.85 47.3797 140.102 48.2142 140.009 49.4196H143.468Z" fill="white" />
  <path d="M145.431 53.5919V52.8965H146.693V47.5187H145.431V46.8234L147.862 46.7306L148.049 48.2141C148.236 47.5651 148.797 46.6379 149.872 46.6379C150.527 46.6379 151.134 47.0088 151.134 47.7505C151.134 48.4459 150.62 48.7705 150.153 48.7705C149.685 48.7705 149.264 48.4923 149.264 47.936C149.264 47.6578 149.545 47.3797 149.545 47.3797C148.47 47.3797 148.096 48.8168 148.096 49.9758V52.8965H149.545V53.5919H145.431Z" fill="white" />
  <path d="M109.202 53.6846C109.202 53.6383 109.202 53.5455 109.155 53.4992C109.015 53.5919 108.828 53.6383 108.641 53.6383C108.174 53.6383 107.8 53.221 107.8 52.8038C107.8 52.3402 108.314 51.8766 108.781 51.8766C109.296 51.8766 109.997 52.2938 109.997 53.221C109.997 53.8237 109.669 54.5655 108.828 55.1218C108.781 55.1218 108.781 55.1681 108.735 55.1681L108.407 54.6582C108.735 54.4727 109.202 54.0555 109.202 53.6846Z" fill="white" />
  <path d="M82.6963 52.9429L81.5744 48.8633L80.2654 52.8966C80.0317 53.592 79.8447 53.8701 79.3305 53.8701C78.9098 53.8701 78.676 53.6383 78.5358 52.9429L77.0866 47.5188H76.0114V46.8234H79.611V47.5188H78.5825L79.6577 52.2012L81.1536 47.5188H82.3223L83.7247 52.2475L84.9402 47.5188H83.865V46.8234H86.8101V47.5188H85.9219L84.4727 52.8966C84.2857 53.592 84.0987 53.8238 83.6312 53.8238C83.117 53.8238 82.8833 53.592 82.6963 52.9429Z" fill="white" />
  <path d="M90.971 52.8965V53.5919H87.091V52.8965H88.3064V47.5188H87.0442V46.8234H89.6621V52.8965H90.971ZM88.8674 43.7173C89.3816 43.7173 89.8023 44.1345 89.8023 44.6908C89.8023 45.2008 89.3816 45.618 88.8674 45.618C88.3532 45.618 87.9324 45.2008 87.9324 44.6908C87.9324 44.1345 88.3532 43.7173 88.8674 43.7173Z" fill="white" />
  <path d="M91.2981 52.8965H92.5603V47.5188H91.2981V46.8234L93.729 46.7307L93.8692 48.1214C94.3367 47.1015 95.3651 46.6843 96.1598 46.6843C96.9545 46.6843 98.2635 46.9625 98.2635 49.1414V52.9429H99.5724V53.6383H95.7391V52.9429H96.9078V49.1414C96.9078 47.9824 96.3936 47.5188 95.6924 47.5188C94.4302 47.5188 93.916 48.7241 93.916 49.9295V52.9429H95.0846V53.6383H91.2981V52.8965Z" fill="white" />
  <path d="M103.079 46.5916C104.809 46.5916 106.164 47.797 106.211 50.0686H101.303V50.115C101.303 51.5057 101.91 52.7575 103.313 52.7575C104.154 52.7575 105.042 52.2475 105.37 51.1812L106.118 51.413C105.79 52.8038 104.762 53.6847 103.219 53.6847C101.069 53.6847 99.9003 51.9694 99.9003 50.2077C99.8068 48.3533 100.975 46.5916 103.079 46.5916ZM104.809 49.4196C104.762 48.1678 104.014 47.3797 103.126 47.3797C102.191 47.3797 101.443 48.2142 101.349 49.4196H104.809Z" fill="white" />
  <path d="M160.951 52.6184C160.156 53.2674 159.408 53.7774 158.193 53.7774C156.556 53.7774 155.434 52.8502 155.434 51.4594C155.434 49.8368 156.229 49.5586 158.006 48.3533C157.538 47.5652 157.071 46.6843 157.071 46.0817C157.071 44.8299 158.099 43.9955 159.875 43.9955C161.044 43.9955 162.166 44.7372 162.166 45.7571C162.166 46.8698 161.512 47.5652 159.922 48.5851L161.371 50.6713L163.288 48.3996H162.213V47.3797H165.906V48.3996H164.737L161.979 51.6448C162.4 52.3402 162.867 52.7575 163.335 52.7575C163.849 52.7575 164.41 52.7111 164.41 51.274V50.3468H165.672V51.1349C165.672 52.7575 165.345 53.8701 163.428 53.8701C162.587 53.8237 161.886 53.6383 160.951 52.6184ZM160.249 51.6448L158.66 49.3268C157.538 50.0222 157.164 50.5786 157.164 51.1349C157.164 52.1084 157.819 52.4793 158.52 52.4793C159.127 52.4329 159.829 52.1084 160.249 51.6448ZM160.904 45.8962C160.904 45.2935 160.436 44.969 159.922 44.969C159.221 44.969 158.707 45.2472 158.707 45.9889C158.707 46.4989 159.127 47.1016 159.408 47.6115C160.203 47.0088 160.904 46.7307 160.904 45.8962Z" fill="white" />
  <path d="M176.285 52.9429L175.163 48.8633L173.854 52.8966C173.62 53.592 173.433 53.8701 172.919 53.8701C172.498 53.8701 172.264 53.6383 172.124 52.9429L170.675 47.5188H169.6V46.8234H173.199V47.5188H172.171L173.246 52.2012L174.742 47.5188H175.911L177.313 52.2475L178.529 47.5188H177.453V46.8234H180.399V47.5188H179.51L178.061 52.8966C177.874 53.592 177.687 53.8238 177.22 53.8238C176.705 53.8238 176.472 53.592 176.285 52.9429Z" fill="white" />
  <path d="M184.558 52.8965V53.5919H180.678V52.8965H181.894V47.5188H180.631V46.8234H183.249V52.8965H184.558ZM182.501 43.7173C183.016 43.7173 183.436 44.1345 183.436 44.6908C183.436 45.2008 183.016 45.618 182.501 45.618C181.987 45.618 181.566 45.2008 181.566 44.6908C181.52 44.1345 181.94 43.7173 182.501 43.7173Z" fill="white" />
  <path d="M187.598 52.8966H188.954V53.592H184.793V52.8966H186.195V44.83H184.793V44.0418H187.598V52.8966Z" fill="white" />
  <path d="M192.412 53.7774C190.729 53.7774 189.608 52.3402 189.608 50.2077C189.608 48.0751 190.729 46.638 192.506 46.638C193.207 46.638 193.955 47.0089 194.376 47.6579V44.8763H192.88V44.0418H195.778V52.8502H196.947V53.5456H194.656L194.423 52.4793C193.955 53.3602 193.114 53.7774 192.412 53.7774ZM191.103 50.2077C191.103 51.5521 191.618 52.9429 192.74 52.9429C193.768 52.9429 194.516 51.7376 194.516 50.115C194.516 48.8169 194.002 47.3334 192.786 47.3334C191.524 47.3334 191.103 48.8169 191.103 50.2077Z" fill="white" />
  <path d="M200.734 46.5916C202.463 46.5916 203.819 47.797 203.866 50.0686H198.957V50.115C198.957 51.5057 199.565 52.7575 200.967 52.7575C201.809 52.7575 202.697 52.2475 203.024 51.1812L203.772 51.413C203.445 52.8038 202.417 53.6847 200.874 53.6847C198.724 53.6847 197.555 51.9694 197.555 50.2077C197.461 48.3533 198.63 46.5916 200.734 46.5916ZM202.463 49.4196C202.417 48.1678 201.669 47.3797 200.781 47.3797C199.846 47.3797 199.098 48.2142 199.004 49.4196H202.463Z" fill="white" />
  <path d="M204.427 53.5919V52.8965H205.689V47.5187H204.427V46.8234L206.857 46.7306L207.044 48.2141C207.231 47.5651 207.792 46.6379 208.868 46.6379C209.522 46.6379 210.13 47.0088 210.13 47.7505C210.13 48.4459 209.615 48.7705 209.148 48.7705C208.681 48.7705 208.26 48.4923 208.26 47.936C208.26 47.6578 208.54 47.3797 208.54 47.3797C207.465 47.3797 207.091 48.8168 207.091 49.9758V52.8965H208.54V53.5919H204.427Z" fill="white" />
  <path d="M210.504 52.8965H211.766V47.5188H210.504V46.8234L212.935 46.7307L213.075 48.1214C213.543 47.1015 214.571 46.6843 215.366 46.6843C216.161 46.6843 217.47 46.9625 217.47 49.1414V52.9429H218.778V53.6383H214.945V52.9429H216.114V49.1414C216.114 47.9824 215.6 47.5188 214.898 47.5188C213.636 47.5188 213.122 48.7241 213.122 49.9295V52.9429H214.291V53.6383H210.504V52.8965Z" fill="white" />
  <path d="M222.331 46.5916C224.061 46.5916 225.417 47.797 225.463 50.0686H220.555V50.115C220.555 51.5057 221.163 52.7575 222.565 52.7575C223.406 52.7575 224.295 52.2475 224.622 51.1812L225.37 51.413C225.043 52.8038 224.014 53.6847 222.471 53.6847C220.321 53.6847 219.152 51.9694 219.152 50.2077C219.059 48.3533 220.181 46.5916 222.331 46.5916ZM224.061 49.4196C224.014 48.1678 223.266 47.3797 222.378 47.3797C221.443 47.3797 220.695 48.2142 220.602 49.4196H224.061Z" fill="white" />
  <path d="M226.443 52.062C226.443 51.5057 226.864 51.1348 227.331 51.1348C227.752 51.1348 228.173 51.3666 228.173 51.9229C228.173 52.3865 227.846 52.6647 227.565 52.6647C227.892 52.8965 228.5 53.0819 229.061 53.0819C229.949 53.0819 230.884 52.8038 230.884 51.9693C230.884 50.254 226.49 50.9957 226.49 48.585C226.49 47.1015 228.22 46.6379 229.154 46.6379C230.323 46.6379 231.866 47.1479 231.866 48.1678C231.866 48.7705 231.445 49.1413 231.024 49.1413C230.557 49.1413 230.136 48.8632 230.136 48.3069C230.136 47.8896 230.417 47.7505 230.604 47.6115C230.23 47.426 229.715 47.2869 229.154 47.2869C228.313 47.2869 227.612 47.6578 227.612 48.3996C227.612 49.7904 232.006 49.1877 232.006 51.7375C232.006 53.3137 230.51 53.7773 229.154 53.7773C227.939 53.7773 226.443 53.3137 226.443 52.062Z" fill="white" />
  <path d="M233.082 52.062C233.082 51.5057 233.503 51.1348 233.97 51.1348C234.391 51.1348 234.812 51.3666 234.812 51.9229C234.812 52.3865 234.484 52.6647 234.204 52.6647C234.531 52.8965 235.139 53.0819 235.7 53.0819C236.588 53.0819 237.523 52.8038 237.523 51.9693C237.523 50.254 233.129 50.9957 233.129 48.585C233.129 47.1015 234.858 46.6379 235.793 46.6379C236.962 46.6379 238.505 47.1479 238.505 48.1678C238.505 48.7705 238.084 49.1413 237.663 49.1413C237.196 49.1413 236.775 48.8632 236.775 48.3069C236.775 47.8896 237.056 47.7505 237.243 47.6115C236.869 47.426 236.354 47.2869 235.793 47.2869C234.952 47.2869 234.251 47.6578 234.251 48.3996C234.251 49.7904 238.645 49.1877 238.645 51.7375C238.645 53.3137 237.149 53.7773 235.793 53.7773C234.625 53.7773 233.082 53.3137 233.082 52.062Z" fill="white" />
  <path d="M244.675 44.0418C244.769 44.0418 244.862 44.1346 244.862 44.2736V46.7771H247.387V47.5188H244.862V51.7839C244.862 52.572 245.236 52.8966 245.797 52.8966C246.405 52.8966 246.826 52.572 246.826 51.4131V50.4859H247.574V51.274C247.574 52.8502 247.153 53.7774 245.517 53.7774C244.488 53.7774 243.46 53.1747 243.46 51.7376V47.5188H242.151V47.2407C243.927 46.6844 244.255 44.0418 244.675 44.0418Z" fill="white" />
  <path d="M248.462 50.1613C248.462 48.1215 249.724 46.5916 251.781 46.5916C253.884 46.5916 255.1 48.1678 255.1 50.1613C255.1 52.1548 253.884 53.731 251.781 53.731C249.724 53.7774 248.462 52.2011 248.462 50.1613ZM249.911 50.254C249.911 51.8766 250.612 52.9429 251.781 52.9429C252.949 52.9429 253.651 51.8303 253.651 50.254C253.651 48.5851 253.09 47.3334 251.781 47.3334C250.472 47.3334 249.911 48.6314 249.911 50.254Z" fill="white" />
  <path d="M261.083 52.3402C260.476 53.4529 259.587 53.7774 258.793 53.7774C257.998 53.7774 256.642 53.4992 256.642 51.3203V47.5188H255.427V46.8234H257.998V51.3203C257.998 52.4793 258.372 52.8966 259.12 52.8966C259.961 52.8966 261.037 52.0621 261.037 51.2276V47.5188H259.915V46.8234H262.439V52.8502L263.701 52.9893V53.592L261.13 53.7774V52.3402H261.083Z" fill="white" />
  <path d="M263.982 53.5919V52.8965H265.245V47.5187H263.982V46.8234L266.413 46.7306L266.6 48.2141C266.787 47.5651 267.348 46.6379 268.423 46.6379C269.078 46.6379 269.686 47.0088 269.686 47.7505C269.686 48.4459 269.171 48.7705 268.704 48.7705C268.236 48.7705 267.816 48.4923 267.816 47.936C267.816 47.6578 268.096 47.3797 268.096 47.3797C267.021 47.3797 266.647 48.8168 266.647 49.9758V52.8965H268.096V53.5919H263.982Z" fill="white" />
  <path d="M270.294 52.062C270.294 51.5057 270.714 51.1348 271.182 51.1348C271.603 51.1348 272.023 51.3666 272.023 51.9229C272.023 52.3865 271.696 52.6647 271.416 52.6647C271.743 52.8965 272.351 53.0819 272.911 53.0819C273.8 53.0819 274.735 52.8038 274.735 51.9693C274.735 50.254 270.34 50.9957 270.34 48.585C270.34 47.1015 272.07 46.6379 273.005 46.6379C274.174 46.6379 275.716 47.1479 275.716 48.1678C275.716 48.7705 275.296 49.1413 274.875 49.1413C274.407 49.1413 273.987 48.8632 273.987 48.3069C273.987 47.8896 274.267 47.7505 274.454 47.6115C274.08 47.426 273.566 47.2869 273.005 47.2869C272.164 47.2869 271.462 47.6578 271.462 48.3996C271.462 49.7904 275.857 49.1877 275.857 51.7375C275.857 53.3137 274.361 53.7773 273.005 53.7773C271.836 53.7773 270.294 53.3137 270.294 52.062Z" fill="white" />
  <defs>
    <linearGradient id="XV2BFO0_linear" x1="327.121" y1="22.9722" x2="322.655" y2="40.7036" gradientUnits="userSpaceOnUse">
      <stop stopColor="#99080F" />
      <stop offset="0.4067" stopColor="#7D0E14" />
      <stop offset="1" stopColor="#4D191D" />
    </linearGradient>
    <linearGradient id="XV2BFO1_linear" x1="297.828" y1="46.5618" x2="379.242" y2="57.3489" gradientUnits="userSpaceOnUse">
      <stop stopColor="#B51C19" />
      <stop offset="0.8133" stopColor="#52191D" />
    </linearGradient>
    <linearGradient id="XV2BFO2_linear" x1="333.064" y1="9.8216" x2="352.871" y2="9.8216" gradientUnits="userSpaceOnUse">
      <stop stopColor="#99080F" />
      <stop offset="1" stopColor="#63191D" />
    </linearGradient>
  </defs>
</svg>
