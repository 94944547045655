import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Hashtag } from '../../constants/hashtags.constants';
import { EscapeToMarlboroughLogoDefault } from '../../content/logos/escape-to-marlborough';
import { EscapeTourismLogo } from '../../content/logos/escape-tourism.logos';
import { ICSLogoDefault } from '../../content/logos/ics.logos';
import { JimOCallaghanLogoDefault } from '../../content/logos/jim.logos';
import { BridgeConsultingLogoDefault } from '../../content/logos/mjhudson-bridge.logos';
import { SoluColLogoDefault } from '../../content/logos/solucol.logos';
import { TheGablesLogoDefault } from '../../content/logos/the-gables.logos';
import { TougherOilLogoDefault } from '../../content/logos/tougheroil.logos';
import { XMusicLogoDefault } from '../../content/logos/xmusic.logos';
import { useFloatInOnVisible } from '../../hooks/useFloatInOnVisible.transition.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import GridRow from '../GridRow/GridRow';
import './DesignGalleryBranding.scss';

type DesignGalleryBrandingProps = {}

const DesignGalleryBrandingItem = (props: React.PropsWithChildren<{
  // entry?: EntryMetaInfo,
  id: string,
  background?: string
}>) => {
  const id = `DesignGallery--branding--${props.id}`;
  const ref = useObservableRef();
  useFloatInOnVisible(id, ref);
  return <div
    id={id}
    className="DesignGalleryBrandingItem"
    style={{ background: props.background }}
    ref={ref}
  >
    {props.children}
  </div>
}
const DesignGalleryBranding: React.FC<DesignGalleryBrandingProps> = props => {
  // const DesignPageGallery = useStaticQuery(graphql`
  //   query DesignPageGallery {

  //   }
  // `) 
  return <Observer children={() => (
    <div id={Hashtag.branding} className="DesignGallery DesignGalleryBranding">
      <header className="DesignGalleryHeader">
        <h2>#Branding</h2>
      </header>
      <GridRow>
        <DesignGalleryBrandingItem id="f1">
          { TougherOilLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f2"> {/* square */}
          { TheGablesLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f3"
          // background="radial-gradient(68.36% 68.36% at 52.86% 11.39%, rgba(8, 17, 36, 0.57) 0%, rgba(8, 17, 36, 0.26) 100%), radial-gradient(89.54% 252.33% at 4.25% 15.15%, #081124 0%, rgba(8, 17, 36, 0.76) 100%), linear-gradient(203.56deg, #081124 -3.98%, rgba(8, 17, 36, 0.46) 87.56%)"
        >
          { EscapeToMarlboroughLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f4">
          { XMusicLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f5"> {/* square */}
          { BridgeConsultingLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f6">
          { ICSLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f7"> {/* square */}
          { SoluColLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f8"> {/* square */}
          { JimOCallaghanLogoDefault }
        </DesignGalleryBrandingItem>
        <DesignGalleryBrandingItem id="f9"> {/* square */}
          { EscapeTourismLogo }
        </DesignGalleryBrandingItem>
      </GridRow>
    </div>
  )} />
}

export default DesignGalleryBranding;