import React from 'react';
export const JimOCallaghanLogoDefault = <svg width="133" height="87" viewBox="0 0 133 87" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ aspectRatio: '133/87' }}>
  <path d="M48.0162 0V15.8476H30.1393V0H48.0162ZM48.0162 19.9062V69.8649H30.1393V19.9062H48.0162Z" fill="#045D53" />
  <path d="M51.9666 69.8649V19.9062H70.4716V26.8154C72.1144 24.625 74.2886 22.7729 76.9943 21.259C79.7 19.7129 83.0016 18.9399 86.8991 18.9399C89.3149 18.9399 91.7951 19.6163 94.3397 20.9691C96.8844 22.2898 98.9781 24.2385 100.621 26.8154C102.264 24.6895 104.389 22.8535 106.999 21.3074C109.608 19.729 112.49 18.9399 115.647 18.9399C118.224 18.9399 120.769 19.568 123.281 20.8242C125.793 22.0482 127.871 23.9647 129.514 26.5738C131.189 29.1506 132.026 32.5006 132.026 36.6235V69.8649H113.183V39.1843C113.183 37.5415 112.539 36.2692 111.25 35.3673C109.962 34.4654 108.609 34.0145 107.192 34.0145C105.807 34.0145 104.47 34.4654 103.182 35.3673C101.925 36.2692 101.297 37.5415 101.297 39.1843V69.8649H82.5506V39.1843C82.5506 37.5415 81.9225 36.2692 80.6663 35.3673C79.4101 34.4654 78.0734 34.0145 76.6561 34.0145C75.271 34.0145 73.9343 34.4654 72.6459 35.3673C71.3896 36.2692 70.7615 37.5415 70.7615 39.1843V69.8649H51.9666Z" fill="#045D53" />
  <path d="M21.7951 81.1709C25.0162 78.0142 26.6267 73.3437 26.6267 67.1592V5.58751H7.83178V67.1592C7.83178 68.1578 7.50968 68.9952 6.86546 69.6717C6.25346 70.3159 5.06167 70.8312 3.29008 71.2178L0.342804 71.7009L0.439435 84.2631L1.79228 85.0844C2.72639 85.632 4.44966 85.9058 6.9621 85.9058C13.6297 85.9058 18.574 84.3275 21.7951 81.1709Z" fill="#045D53" />
  <path d="M34.3345 83.6734C33.341 83.6734 32.4792 83.4631 31.7491 83.0426C31.0237 82.6175 30.4622 82.0214 30.0648 81.2544C29.6674 80.4873 29.4688 79.5839 29.4688 78.5443C29.4688 77.4999 29.6698 76.592 30.0718 75.8203C30.4784 75.044 31.0468 74.4433 31.7769 74.0181C32.507 73.5884 33.3595 73.3735 34.3345 73.3735C35.3049 73.3735 36.1528 73.5884 36.8783 74.0181C37.6037 74.4433 38.1675 75.044 38.5695 75.8203C38.9715 76.592 39.1725 77.4999 39.1725 78.5443C39.1725 79.5793 38.9738 80.4827 38.5764 81.2544C38.1837 82.0214 37.6245 82.6175 36.8991 83.0426C36.1736 83.4631 35.3187 83.6734 34.3345 83.6734ZM34.3345 81.5108C34.7042 81.5108 35.0322 81.4184 35.3187 81.2336C35.6098 81.0441 35.8363 80.7368 35.998 80.3117C36.1643 79.882 36.2475 79.3067 36.2475 78.5858C36.2475 77.8419 36.1643 77.2481 35.998 76.8045C35.8363 76.3563 35.6098 76.0328 35.3187 75.8341C35.0322 75.6354 34.7042 75.5361 34.3345 75.5361C33.9648 75.5361 33.6344 75.6354 33.3433 75.8341C33.0568 76.0328 32.8304 76.3563 32.6641 76.8045C32.4977 77.2481 32.4145 77.8419 32.4145 78.5858C32.4145 79.3067 32.4977 79.882 32.6641 80.3117C32.8304 80.7368 33.0568 81.0441 33.3433 81.2336C33.6344 81.4184 33.9648 81.5108 34.3345 81.5108Z" fill="#CEB37D" />
  <path d="M40.457 77.9759L41.2887 75.9381L40.4847 75.8411V73.5122H42.9384V75.5777L41.3442 77.9759H40.457Z" fill="#CEB37D" />
  <path d="M49.1566 73.3735C49.9513 73.3735 50.663 73.5306 51.2914 73.8449C51.9198 74.1545 52.4281 74.5865 52.8163 75.141C53.2044 75.6909 53.4355 76.3263 53.5094 77.0471H50.6953C50.6214 76.5804 50.4458 76.213 50.1685 75.945C49.8959 75.6724 49.5285 75.5361 49.0665 75.5361C48.7291 75.5361 48.4288 75.6239 48.1654 75.7995C47.902 75.9751 47.6941 76.2754 47.5416 76.7005C47.3937 77.121 47.3198 77.6986 47.3198 78.4334C47.3198 79.547 47.4838 80.3395 47.8119 80.8108C48.14 81.2775 48.5582 81.5108 49.0665 81.5108C49.5285 81.5108 49.8959 81.3399 50.1685 80.9979C50.4458 80.656 50.6214 80.254 50.6953 79.7919H53.5094C53.4401 80.3094 53.3223 80.8015 53.1559 81.2682C52.9942 81.7349 52.7516 82.1508 52.4281 82.5159C52.1093 82.8763 51.6842 83.1605 51.1528 83.3684C50.6214 83.5717 49.956 83.6734 49.1566 83.6734C48.1908 83.6734 47.3475 83.4447 46.6267 82.9872C45.9104 82.5297 45.3536 81.9036 44.9562 81.1088C44.5588 80.314 44.3601 79.4084 44.3601 78.3918C44.3601 77.3752 44.5565 76.4926 44.9493 75.744C45.3421 74.9908 45.8966 74.4086 46.6128 73.9973C47.3336 73.5815 48.1816 73.3735 49.1566 73.3735Z" fill="#CEB37D" />
  <path d="M53.8884 83.5348L57.4373 73.5122H60.1959L63.7447 83.5348H61.0831L60.4177 81.594H57.257L56.5778 83.5348H53.8884ZM57.8254 79.2928H59.8077L58.8235 76.0906L57.8254 79.2928Z" fill="#CEB37D" />
  <path d="M64.8913 73.5122H67.5875V81.3168H71.5938V83.5348H64.8913V73.5122Z" fill="#CEB37D" />
  <path d="M72.9055 73.5122H75.6018V81.3168H79.6081V83.5348H72.9055V73.5122Z" fill="#CEB37D" />
  <path d="M80.3951 83.5348L83.9439 73.5122H86.7025L90.2513 83.5348H87.5897L86.9243 81.594H83.7637L83.0844 83.5348H80.3951ZM84.3321 79.2928H86.3144L85.3302 76.0906L84.3321 79.2928Z" fill="#CEB37D" />
  <path d="M95.305 83.6734C94.3531 83.6734 93.5214 83.4585 92.8097 83.0288C92.0981 82.5944 91.5459 81.9937 91.1532 81.2267C90.7604 80.455 90.564 79.5655 90.564 78.5581C90.564 77.5184 90.772 76.6104 91.1878 75.8341C91.6083 75.0532 92.1859 74.4479 92.9206 74.0181C93.66 73.5884 94.5102 73.3735 95.4713 73.3735C96.146 73.3735 96.7513 73.4752 97.2873 73.6785C97.8234 73.8818 98.2808 74.1591 98.6597 74.5103C99.0433 74.8568 99.3367 75.2496 99.54 75.6886C99.7433 76.1276 99.8496 76.5804 99.8588 77.0471H97.0378C96.95 76.6358 96.7721 76.2939 96.5041 76.0213C96.2407 75.744 95.8734 75.6054 95.402 75.6054C95.074 75.6054 94.7644 75.6955 94.4732 75.8757C94.1868 76.0513 93.9534 76.3494 93.7732 76.7699C93.5976 77.1857 93.5098 77.7541 93.5098 78.4749C93.5098 79.0895 93.5722 79.5863 93.6969 79.9652C93.8263 80.3395 93.9904 80.6259 94.1891 80.8246C94.3924 81.0233 94.6119 81.1573 94.8475 81.2267C95.0832 81.296 95.3073 81.3306 95.5199 81.3306C95.8988 81.3306 96.2176 81.2313 96.4764 81.0326C96.7351 80.8293 96.9015 80.6144 96.9754 80.388L97.0933 80.0275H95.3466V78.2116L99.9351 78.2393V83.5348H98.1676V82.4258C98.0013 82.5967 97.8003 82.7793 97.5646 82.9733C97.3335 83.1674 97.0378 83.3338 96.6774 83.4724C96.317 83.6064 95.8595 83.6734 95.305 83.6734Z" fill="#CEB37D" />
  <path d="M107.909 79.5146H104.45V83.5348H101.754V73.5122H104.45V77.3105H107.909V73.5122H110.605V83.5348H107.909V79.5146Z" fill="#CEB37D" />
  <path d="M111.748 83.5348L115.297 73.5122H118.056L121.604 83.5348H118.943L118.277 81.594H115.117L114.438 83.5348H111.748ZM115.685 79.2928H117.668L116.683 76.0906L115.685 79.2928Z" fill="#CEB37D" />
  <path d="M129.377 73.5122H132.074V83.5348H129.641L125.447 77.56V83.5348H122.751V73.5122H125.468L129.377 79.0988V73.5122Z" fill="#CEB37D" />
</svg>
