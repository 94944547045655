import React from 'react';

export const BridgeConsultingLogoDefault = <svg width="175" height="146" viewBox="0 0 175 146" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ aspectRatio: '175 / 146' }}>
  <path d="M122.442 0H81.2549L122.442 41.6798V0Z" fill="#72B3A2" />
  <path d="M97.9213 48.4471H122.441V41.6798L81.2548 0L73.2817 23.6093L97.9213 48.4471Z" fill="#85B780" />
  <path d="M64.8955 48.447H97.9181L73.2825 23.6092L64.8955 48.447Z" fill="#85B780" />
  <path d="M73.2815 23.6093L81.2546 0H50.0869V48.4471H64.8945L73.2815 23.6093Z" fill="#5AA080" />
  <path d="M64.8949 48.4473L56.5039 73.2489H122.442V48.4473H64.8949Z" fill="#414E7E" />
  <path d="M50.0869 48.4473V73.2489H56.5036L64.8945 48.4473H50.0869Z" fill="#85B780" />
  <path d="M50.0869 48.4473V73.2489H56.5036L64.8945 48.4473H50.0869Z" fill="#233941" />
  <path d="M97.9213 48.4471H122.441V41.6798L81.2548 0L73.2817 23.6093L97.9213 48.4471Z" fill="#539479" />
  <path d="M64.8955 48.447H97.9181L73.2825 23.6092L64.8955 48.447Z" fill="#3C7063" />
  <path d="M1.37121 118H10.8232C17.4532 118 21.6352 115.552 21.6352 111.234C21.6352 107.834 19.2892 105.794 14.7332 105.42V105.25C17.8952 104.57 20.1392 102.326 20.1392 99.844C20.1392 95.662 16.3992 94.2 10.5512 94.2H1.37121V96.002L3.61521 96.138C4.73721 96.206 4.83921 96.784 4.83921 97.736V114.328C4.83921 115.756 4.46521 116.028 3.68321 116.062L1.37121 116.198V118ZM10.2452 104.672C9.25921 104.672 8.64721 104.672 8.17121 104.604V96.172H10.3812C14.0872 96.172 16.3992 96.818 16.3992 99.98C16.3992 103.006 13.9852 104.672 10.2452 104.672ZM10.8232 116.028C9.66721 116.028 8.68121 115.96 8.17121 115.858V106.78C8.85121 106.678 9.70121 106.644 11.0272 106.644C15.3112 106.644 17.8612 108.106 17.8612 111.132C17.8612 114.396 15.3112 116.028 10.8232 116.028Z" fill="#414E7E" />
  <path d="M51.8511 118.476C53.3131 118.476 54.4691 118.034 55.4891 117.116L55.0811 115.824C54.2991 116.062 53.8911 116.13 53.4491 116.13C51.6131 116.13 51.0691 115.28 49.7771 112.492L48.4851 109.704C47.6011 107.8 46.6491 107.256 44.8131 106.712V106.61C48.7231 105.998 51.1711 103.516 51.1711 100.286C51.1711 96.002 47.8391 94.2 41.6171 94.2H32.1651V96.002L34.4091 96.138C35.5311 96.206 35.6331 96.784 35.6331 97.736V114.328C35.6331 115.756 35.2591 116.028 34.4771 116.062L32.1651 116.198V118H42.7391V116.198L40.3251 116.062C39.2031 115.994 38.9651 115.688 38.9651 114.362V107.358C39.4751 107.29 40.0531 107.256 40.7331 107.256C43.3511 107.256 43.8271 108.276 44.7111 110.214L46.4791 114.124C47.9751 117.456 49.3011 118.476 51.8511 118.476ZM41.1411 105.454C40.6311 105.454 39.6791 105.42 38.9651 105.386V96.172H41.0731C44.9151 96.172 47.4311 97.022 47.4311 100.49C47.4311 103.652 45.0511 105.454 41.1411 105.454Z" fill="#414E7E" />
  <path d="M64.2871 118H74.5552V116.198L72.2431 116.062C71.2231 115.994 71.0872 115.484 71.0872 114.464V97.872C71.0872 96.614 71.3251 96.172 72.1751 96.138L74.5552 96.002V94.2H64.2871V96.002L66.5312 96.138C67.6532 96.206 67.7551 96.784 67.7551 97.736V114.328C67.7551 115.722 67.4151 116.028 66.6331 116.062L64.2871 116.198V118Z" fill="#414E7E" />
  <path d="M85.5518 118H95.2078C103.946 118 109.386 113.376 109.386 105.352C109.386 98.042 104.83 94.2 96.1598 94.2H85.5518V96.002L87.7958 96.138C88.8158 96.206 89.0198 96.58 89.0198 97.736V114.328C89.0198 115.62 88.8158 115.994 87.8638 116.062L85.5518 116.198V118ZM95.1398 115.96H92.3518V96.24H95.9898C102.45 96.24 105.646 99.334 105.646 105.624C105.646 112.832 102.45 115.96 95.1398 115.96Z" fill="#414E7E" />
  <path d="M131.757 118.476C134.307 118.476 136.789 117.762 138.693 116.368L139.645 118.102H141.345V110.316C141.345 108.956 141.651 108.65 142.433 108.582L144.235 108.446V106.644H134.511V108.446L136.755 108.582C137.877 108.65 137.979 109.194 137.979 110.18V114.022C136.755 115.246 134.851 115.892 132.369 115.892C126.793 115.892 123.767 111.608 123.767 105.556C123.767 99.3 126.623 96.036 131.587 96.036C135.429 96.036 138.047 97.668 139.543 101.748L141.447 101.34L140.019 93.724H138.353L137.979 95.764C136.381 94.506 134.375 93.724 131.485 93.724C124.583 93.724 120.027 98.28 120.027 106.27C120.027 113.478 124.447 118.476 131.757 118.476Z" fill="#414E7E" />
  <path d="M154.013 118H172.169L172.951 111.574H171.047C170.027 115.212 169.041 115.96 165.573 115.96H160.813V106.916H163.941C166.355 106.916 166.763 107.154 167.273 109.942L168.905 109.67V101.85L167.273 102.054C166.593 104.638 166.457 104.876 164.009 104.876H160.813V96.24H164.519C167.919 96.24 168.837 96.58 170.367 100.49L172.203 99.912L170.945 94.2H154.013V96.002L156.291 96.138C157.277 96.206 157.481 96.614 157.481 97.736V114.328C157.481 115.688 157.175 116.028 156.359 116.062L154.013 116.198V118Z" fill="#414E7E" />
  <path d="M7.18819 145.266C9.77219 145.266 11.7292 144.05 12.7932 142.511L11.7672 141.485C10.7602 142.625 9.52519 143.347 7.66319 143.347C5.15519 143.347 3.21719 141.637 3.21719 137.97C3.21719 134.854 4.60419 133.087 7.24519 133.087C9.14519 133.087 10.3802 134.018 11.1782 136.26L12.5272 135.975L11.7672 131.434H10.6462L10.3992 132.536C9.56319 131.852 8.49919 131.434 7.07419 131.434C3.06519 131.434 0.367188 134.132 0.367188 138.369C0.367188 142.625 2.95119 145.266 7.18819 145.266Z" fill="#414E7E" />
  <path d="M24.9527 145.266C29.3037 145.266 32.0017 142.226 32.0017 138.027C32.0017 133.942 29.3037 131.434 25.2377 131.434C20.8677 131.434 18.1507 134.113 18.1507 138.597C18.1507 142.53 20.6777 145.266 24.9527 145.266ZM25.1997 143.556C22.5777 143.556 21.0197 141.694 21.0197 138.236C21.0197 134.778 22.4257 133.125 24.9337 133.125C27.5747 133.125 29.1327 134.892 29.1327 138.369C29.1327 141.96 27.7457 143.556 25.1997 143.556Z" fill="#414E7E" />
  <path d="M37.8101 145H43.1871V143.727L42.0851 143.651C41.4581 143.613 41.4201 143.309 41.4201 142.758V134.816H41.5151L48.4501 145H51.1481V133.999C51.1481 133.296 51.3001 133.068 51.7941 133.03L52.8771 132.973V131.7H47.5571V132.973L48.5831 133.03C49.1341 133.087 49.2671 133.296 49.2671 133.923V141.542H49.1911L42.7121 131.7H37.8101V132.973L38.9121 133.03C39.4631 133.068 39.5581 133.296 39.5581 133.942V142.682C39.5581 143.423 39.4061 143.632 38.9501 143.651L37.8101 143.727V145Z" fill="#414E7E" />
  <path d="M64.0914 145.266C66.7324 145.266 68.9174 143.67 68.9174 141.086C68.9174 138.806 67.5304 137.837 64.5094 136.963C62.3624 136.355 61.4884 135.785 61.4884 134.645C61.4884 133.562 62.4004 133.011 63.7114 133.011C65.2504 133.011 66.4854 133.657 67.1694 135.652L68.4804 135.367L68.1004 131.434H66.9794L66.6944 132.384C65.8774 131.795 64.8134 131.434 63.5974 131.434C60.5004 131.434 58.9424 133.41 58.9424 135.348C58.9424 137.628 60.3294 138.654 63.1604 139.433C65.4594 140.079 66.2954 140.668 66.2954 141.884C66.2954 143.024 65.4024 143.594 63.8634 143.594C62.0394 143.594 60.5764 142.739 59.8164 140.744L58.5054 140.915L58.8854 145.114H60.0444L60.3484 144.05C61.2034 144.772 62.6284 145.266 64.0914 145.266Z" fill="#414E7E" />
  <path d="M81.6159 145.266C85.4159 145.266 87.3349 143.423 87.3349 139.699V133.999C87.3349 133.277 87.5249 133.068 87.9619 133.03L89.0069 132.973V131.7H83.8959V132.973L84.8839 133.03C85.4539 133.068 85.5679 133.296 85.5679 133.942V139.908C85.5679 142.264 84.5039 143.48 82.0339 143.48C79.4689 143.48 78.6519 142.207 78.6519 139.984V133.999C78.6519 133.277 78.8419 133.068 79.2789 133.03L80.3049 132.973V131.7H74.2819V132.973L75.2699 133.03C75.8399 133.068 75.9539 133.296 75.9539 133.942V140.041C75.9539 143.29 77.5879 145.266 81.6159 145.266Z" fill="#414E7E" />
  <path d="M94.6861 145H105.079L105.554 140.611H104.072C103.274 143.195 102.799 143.499 101.108 143.499H99.0751V133.923C99.0751 133.277 99.2082 133.087 99.7592 133.049L101.032 132.954V131.7H94.6861V132.973L95.7502 133.03C96.3202 133.068 96.4342 133.296 96.4342 133.923V142.682C96.4342 143.48 96.2442 143.632 95.7882 143.651L94.6861 143.727V145Z" fill="#414E7E" />
  <path d="M111.7 145H118.825V143.727L117.267 143.613C116.678 143.575 116.583 143.366 116.583 142.701V133.144H117.685C119.224 133.144 119.718 133.391 120.535 135.956L121.998 135.671L121.333 131.7H109.192L108.489 135.652L109.952 135.956C110.788 133.372 111.225 133.144 112.859 133.144H113.942V142.644C113.942 143.328 113.79 143.575 113.258 143.613L111.7 143.727V145Z" fill="#414E7E" />
  <path d="M127.47 145H133.626V143.727L132.524 143.651C131.954 143.613 131.859 143.385 131.859 142.758V133.999C131.859 133.277 132.011 133.068 132.486 133.03L133.626 132.973V131.7H127.47V132.973L128.534 133.03C129.142 133.068 129.218 133.353 129.218 133.923V142.701C129.218 143.423 129.066 143.632 128.591 143.651L127.47 143.727V145Z" fill="#414E7E" />
  <path d="M139.52 145H144.897V143.727L143.795 143.651C143.168 143.613 143.13 143.309 143.13 142.758V134.816H143.225L150.16 145H152.858V133.999C152.858 133.296 153.01 133.068 153.504 133.03L154.587 132.973V131.7H149.267V132.973L150.293 133.03C150.844 133.087 150.977 133.296 150.977 133.923V141.542H150.901L144.422 131.7H139.52V132.973L140.622 133.03C141.173 133.068 141.268 133.296 141.268 133.942V142.682C141.268 143.423 141.116 143.632 140.66 143.651L139.52 143.727V145Z" fill="#414E7E" />
  <path d="M166.604 145.266C168.333 145.266 169.739 144.601 170.594 143.955L171.088 145.038H172.513V140.782C172.513 140.041 172.665 139.851 173.121 139.813L174.014 139.737V138.464H168.181V139.737L169.302 139.794C169.91 139.832 169.986 140.117 169.986 140.706V142.53C169.359 143.062 168.447 143.328 167.231 143.328C164.514 143.328 162.88 141.333 162.88 137.97C162.88 134.816 164.343 133.087 166.908 133.087C168.941 133.087 170.271 133.942 171.088 136.146L172.475 135.88L171.677 131.434H170.556L170.328 132.498C169.435 131.833 168.352 131.434 166.756 131.434C162.709 131.434 160.03 134.037 160.03 138.445C160.03 142.435 162.424 145.266 166.604 145.266Z" fill="#414E7E" />
</svg>
