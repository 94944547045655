import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { EscapeToMarlboroughImagesQuery } from "../../types/generated";

export const ProjectMetaInfoEscapeToMarlborough: ProjectMetaInfo = {
  name: "escape-to-marlborough",
  displayName: "Escape to Marlborough",
  abbreviation: "E2M",
  subtitle: "New Zealand Luxury Wine Tour Operator",
  description: "Escape to Marlborough specialise in tours within the Marlborough region in New Zealand. A complete branding solution with a new website was developed, featuring one of the first interactive maps for tours companies of the same kind in the country.",
  url: 'http://escapetomarlborough.co.nz/',
  categories: [
    Category.websites,
    Category.design,
  ],
  sectors: [
    Sector.tourism,
  ],
  hasPage: false,
  hashtags: [
    Hashtag.branding,
    Hashtag.digitalDesign,
    Hashtag.printDesign,
  ],
  keywords: [
    'branding design',
    'web design',
    'print design',
    'WordPress',
    'New Zealand',
    'Bus Tours',
    'Wine Tours',
    'Private Wine Tours',
    'tourism',
  ],
}
export const useEscapeToMarlboroughInfo = () => {
  const EscapeToMarlboroughImages: EscapeToMarlboroughImagesQuery = useStaticQuery(graphql`
    query EscapeToMarlboroughImages {
      featuredImage: file(relativePath: {eq: "images/escape-to-marlborough/escape-to-marlborough-wineries-view-from-top-of-mountain.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/escape-to-marlborough/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560)
            }
          }
        }
      }
    }`
  )
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "Escape to Marlborough: Homepage Screenshot",
      alt: "Escape to Marlborough: Homepage Screenshot, desktop version",
    },
    {
      title: "Escape to Marlborough: Interactive Maps Design",
      alt: "Escape to Marlborough: Interactive Maps Design",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoEscapeToMarlborough,
    featuredImage: {
      image: EscapeToMarlboroughImages.featuredImage!.childImageSharp,
      title: 'Escape to Marlborough Bus',
      alt: 'A black Escape to Marlborough wine tour bus outside of a winery restaurant',
    },
    gallery: EscapeToMarlboroughImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}